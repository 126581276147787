import React, { useEffect, useRef, useState } from "react";
import { CHAT_API_BASE_URL } from "../../../../config";

import {
  loggedInUser,
  chatSupportActiveContact,
  chatMessages,
} from "../../../../atom/globalState";
import ChatServices from "./services";
import MessageServices from "../../../../services/chatServiceUtil";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilState } from "recoil";
import { truncateName25 } from "../../../../helper/StringSlicer";
import ImageCollage from "../../../../helper/ImageCollage";

//chat support
import { IconButton, TextField } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { Link as RouterLink } from "react-router-dom";
import ChatSupportServices from "./services";
//chat support ends here

var stompClient = null;
const SupportChat = (props) => {
  const currentUser = useRecoilValue(loggedInUser);
  const [contacts, setContacts] = useState([]);
  const [text, setText] = useState("");
  const inputRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [activeContact, setActiveContact] = useRecoilState(
    chatSupportActiveContact
  );
  const [messages, setMessages] = useRecoilState(chatMessages);
  const [forceUpdate, setForceUpdate] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  //extract companyId from token
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  console.log("tokendata", decodedToken);

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      props.history.push("/supervisor");
    }
    connect();
    loadContacts();
  }, []);

  useEffect(() => {
    console.log("activeContacts", activeContact);
    if (activeContact === undefined) return;
    MessageServices.findChatMessages(activeContact.userId, currentUser.id)
      .then((msgs) => {
        console.log("active_contact_messages", msgs);
        setMessages(msgs.data.data);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setMessages([]);
        }
        console.log("No new message");
      });
    loadContacts();
  }, [activeContact, forceUpdate]);

  const connect = () => {
    const Stomp = require("stompjs");
    var SockJS = require("sockjs-client");
    SockJS = new SockJS(CHAT_API_BASE_URL);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
    stompClient.onDisconnect = () => {
      console.log("Disconnected, attempting to reconnect...");
      setTimeout(connect, 5000); // Retry connection after 5 seconds
    };
  };

  const onConnected = () => {
    console.log("connected");
    console.log(currentUser);
    stompClient.subscribe(
      "/user/" + currentUser.id + "/queue/messages",
      onMessageReceived
    );
  };

  const onError = (err) => {
    console.log(err);
  };

  const onMessageReceived = (msg) => {
    debugger;
    const notification = JSON.parse(msg.body);
    console.log("notification", notification);
    const active = JSON.parse(
      sessionStorage.getItem("recoil-persist")
    ).chatSupportActiveContact;

    if (active.userId === notification.senderId) {
      MessageServices.findChatMessage(notification.id).then((message) => {
        const newMessages = JSON.parse(
          sessionStorage.getItem("recoil-persist")
        ).chatMessages;
        newMessages.push(message.data.data);
        console.log("newMessages", message.data.data);
        setMessages(newMessages);
      });
    } else {
      console.info("Received a new message from " + notification.senderName);
    }
    loadContacts();
  };

  const loadContacts = async () => {
    debugger;
    try {
      const companyId = currentUser?.companyId;
      const users = await ChatSupportServices.postUserMessage(companyId);
      console.log("users", users.data);
      setContacts(users.data);
      const updatedUsers = users?.data;
      if (activeContact === undefined && updatedUsers?.length !== null) {
        debugger;
        setActiveContact(updatedUsers?.userSummary);
        console.log("Updated User", updatedUsers?.userSummary);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
      }
    }
  };

  const sendMessage = (msg) => {
    if (msg.trim() !== "") {
      const message = {
        senderId: currentUser.id,
        recipientId: activeContact.userId,
        senderName: currentUser.name,
        recipientName: activeContact.name,
        content: msg,
        timestamp: new Date(),
      };
      stompClient.send("/app/chat", {}, JSON.stringify(message));

      const newMessages = [...messages];
      newMessages.push(message);
      setMessages(newMessages);
    }
  };

  const handleFileChange = (files) => {
    debugger;
    if (files.length > 0) {
      const fileList = Array.from(files);
      uploadImages(fileList);
      clearText();
    }
  };

  const uploadImages = async (fileList) => {
    debugger;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await ChatServices.uploadImages(formData);
      if (response && response.data) {
        console.log("Images uploaded successfully:", response.data);
        sendImagesMessage(response.data.data);
      } else {
        throw new Error("No data returned from uploadImages API");
      }
    } catch (error) {
      console.error("Error uploading images:", error.message);
      throw error;
    }
  };

  const sendImagesMessage = (imageData) => {
    debugger;
    if (imageData !== null && imageData !== "") {
      debugger;

      const message = {
        senderId: currentUser.id,
        recipientId: activeContact.userId,
        senderName: currentUser.name,
        recipientName: activeContact.name,
        content: "",
        chatImages: imageData,
        timestamp: new Date(),
      };
      console.log(JSON.stringify(message));
      stompClient.send("/app/chat/upload", {}, JSON.stringify(message));

      const newMessages = [...messages];
      newMessages.push(message);
      setMessages(newMessages);
    }
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().slice(2);
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();

    const formattedDate = `${day} ${
      months[monthIndex]
    } ${year} ${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedDate;
  }

  const clearText = () => {
    setText("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-12 col-md-8 col-lg-8">
          <div class="card radius-10 pt-2">
            <div class="card-body p-0">
              <div class="d-flex align-items-center ps-3 border-bottom pb-2">
                <div class="chat-user-online">
                  <img
                    src={activeContact && activeContact?.imageName}
                    class="rounded-circle"
                    alt={activeContact?.name}
                    style={{
                      objectFit: "cover",
                      width: "45px",
                      height: "45px",
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // To prevent infinite loops in case the default image also fails
                      e.target.src =
                        "https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"; // Set the URL of the default image here
                    }}
                  />
                </div>
                <div class="flex-grow-1 ms-2">
                  <h6 class="mb-0">
                    {activeContact && truncateName25(activeContact?.name)}
                  </h6>
                </div>
              </div>

              <div
                ref={messagesContainerRef}
                style={{
                  height: "430px",
                  overflowY: "scroll",
                  position: "relative",
                }}
              >
                {messages && messages?.length > 0 ? (
                  messages.map((msg) => (
                    <React.Fragment key={msg.id}>
                      {msg.senderId !== currentUser.id ? (
                        // Left chat
                        <div className="chat-content-leftside mt-2 px-3">
                          <div className="d-flex">
                            <img
                              src={activeContact?.imageName}
                              style={{
                                objectFit: "cover",
                                width: "45px",
                                height: "45px",
                              }}
                              className="rounded-circle"
                              alt="avatar"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"; // Set the URL of the default image here
                              }}
                            />
                            <div className="flex-grow-1 ms-2">
                              {msg?.chatImages?.length > 0 ? (
                                <div className="image-collage">
                                  <ImageCollage
                                    images={msg?.chatImages}
                                    className="photo-item"
                                  />
                                </div>
                              ) : (
                                <p className="chat-left-msg">{msg?.content}</p>
                              )}

                              <p className="mb-0 chat-time">
                                {formatDate(msg.timestamp)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Right chat
                        <div className="chat-content-rightside mt-3">
                          <div className="d-flex ms-auto">
                            <div className="flex-grow-1 me-2">
                              {msg?.chatImages?.length > 0 ? (
                                <div className="image-collage">
                                  <ImageCollage
                                    images={msg?.chatImages}
                                    className="photo-item"
                                  />
                                </div>
                              ) : (
                                <p className="chat-right-msg">{msg.content}</p>
                              )}

                              <p className="mb-2 chat-time text-end">
                                {formatDate(msg.timestamp)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "90%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {t("Start a new conversation with")} {activeContact?.name}
                  </div>
                )}
              </div>

              <div class="chat-footer d-flex align-items-center">
                <div class="flex-grow-1 pe-2">
                  <div class="input-group">
                    <input
                      ref={inputRef}
                      type="text"
                      name="massage"
                      id="message"
                      class="form-control height-50 border-0"
                      onChange={(event) => setText(event.target.value)}
                      placeholder={t("Type a message...")}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          sendMessage(text);
                          clearText();
                        }
                      }}
                      maxLength={999}
                    />
                    <span class="input-group-text">
                      <button class="btn px-2">
                        <label htmlFor="galleryInput">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M5.98242 21C5.43242 21 4.96142 20.804 4.56942 20.412C4.17742 20.02 3.98176 19.5493 3.98242 19V5C3.98242 4.45 4.17842 3.979 4.57042 3.587C4.96242 3.195 5.43309 2.99934 5.98242 3H19.9824C20.5324 3 21.0034 3.196 21.3954 3.588C21.7874 3.98 21.9831 4.45067 21.9824 5V19C21.9824 19.55 21.7864 20.021 21.3944 20.413C21.0024 20.805 20.5318 21.0007 19.9824 21H5.98242ZM5.98242 19H19.9824V5H5.98242V19ZM6.98242 17H18.9824L15.2324 12L12.2324 16L9.98242 13L6.98242 17Z"
                              fill="#279EFF"
                            />
                          </svg>
                        </label>
                        <input
                          type="file"
                          id="galleryInput"
                          accept="image/jpeg, image/png, image/jpg"
                          multiple
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileChange(event.target.files)
                          }
                        />
                      </button>
                      <button
                        class="btn px-2"
                        onClick={() => {
                          sendMessage(text);
                          clearText();
                        }}
                        disabled={!text}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M5.98212 12.001L5.37812 6.56397C5.20512 5.00797 6.80712 3.86497 8.22212 4.53597L20.1661 10.194C21.6911 10.916 21.6911 13.086 20.1661 13.808L8.22212 19.466C6.80712 20.136 5.20512 18.994 5.37812 17.438L5.98212 12.001ZM5.98212 12.001H12.9821"
                            stroke="#279EFF"
                            stroke-width="1.85455"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* chat modal pop-up functionality */}
      {/* <div
        style={{
          position: "fixed",
          top: "90%",
          right: 0,
          transform: "translateY(-50%)",
          animation: "bounce 1s infinite",
        }}
      >
        <IconButton component={RouterLink} to={"/dashboard/supervisor/support"}>
          <img
            src="https://th.bing.com/th/id/OIP.v2_qoB8CWsGaYncWVzPLHQAAAA?pid=ImgDet&w=173&h=173&c=7&dpr=1.1"
            alt="Avatar"
            style={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              backgroundColor: "rgba(30, 115, 255, 1)",
            }}
          />
        </IconButton>
      </div> */}
    </>
  );
};
export default SupportChat;
