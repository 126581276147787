import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ForgetPassword from "./authentication/forgetpassword";
import SupervisorForgotPassword from "./authentication/supervisor/forgotPassword";
import SupervisorLogin from "./authentication/supervisor/login";
import Dashboard from "./dashboard";
import SupervisorHome from "./dashboard/module/supervisor/home";
import SupervisorMessage from "./dashboard/module/supervisor/messages";
import MyProfile from "./dashboard/module/supervisor/myProfile";
import GeneralReport from "./dashboard/module/supervisor/reports/generalReport";
import TaskReport from "./dashboard/module/supervisor/reports/taskReport";
import Request from "./dashboard/module/supervisor/requests";
import Settings from "./dashboard/module/supervisor/settings";
import SupervisorChangePassword from "./dashboard/module/supervisor/settings/changePassword";
import Tasks from "./dashboard/module/supervisor/tasks";
import AssignTask from "./dashboard/module/supervisor/tasks/assignTask";
import TimeSheet from "./dashboard/module/supervisor/timesheet";
import TimeLine from "./dashboard/module/supervisor/timesheet/timeLine";
import WorkersScreen from "./dashboard/module/supervisor/workers";
import WorkerTask from "./dashboard/module/supervisor/workers/workertask";
import Payment from "./payment";

// import TermConditions from "./dashboard/module/supervisor/settings/termConditions";
// import PrivacyPolicy from "./dashboard/module/supervisor/settings/privacyPolicies";
import Notification from "./dashboard/module/supervisor/notification";
import NotFoundPage from "./pageNotFound";

//------ Policies -----------
// import TermCondition from "./policies/termcondition";
import SupportChat from "./dashboard/module/supervisor/chatSupport";
import Failed from "./payment/Failed";
import Success from "./payment/Success";
import SetNewPassword from "./authentication/supervisor/setPassword";

const router = createBrowserRouter([
  {
    index: true,
    path: "/",
    element: <SupervisorLogin />,
  },
  {
    path: "/login",
    element: <SupervisorLogin />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/setnewpassword",
    element: <SetNewPassword />,
  },
  ///----------policy end ------
  {
    path: "/dashboard/supervisor/forgotpassword",
    element: <SupervisorForgotPassword />,
  },
  {
    path: "/success",
    element: <PrivateRoute element={<Success />} fallbackPath="/" />,
  },
  {
    path: "/cancel",
    element: <PrivateRoute element={<Failed />} fallbackPath="/" />,
  },

  {
    path: "/payment",
    element: <PrivateRoute element={<Payment />} fallbackPath="/" />,
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard/supervisor/home",
        element: <PrivateRoute element={<SupervisorHome />} fallbackPath="/" />,
      },

      // ---------------------worker/supervisor----------------------------

      {
        path: "/dashboard/supervisor/myprofile",
        element: <PrivateRoute element={<MyProfile />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/taskreport",
        element: <PrivateRoute element={<TaskReport />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/generalreport",
        element: <PrivateRoute element={<GeneralReport />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/workertask",
        element: <PrivateRoute element={<WorkerTask />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/request",
        element: <PrivateRoute element={<Request />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/settings",
        element: <PrivateRoute element={<Settings />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/messages",
        element: (
          <PrivateRoute element={<SupervisorMessage />} fallbackPath="/" />
        ),
      },
      {
        path: "/dashboard/supervisor/support",
        element: <PrivateRoute element={<SupportChat />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/changepassword",
        element: (
          <PrivateRoute
            element={<SupervisorChangePassword />}
            fallbackPath="/"
          />
        ),
      },

      {
        path: "/dashboard/supervisor/tasks",
        element: <PrivateRoute element={<Tasks />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/assigntask",
        element: <PrivateRoute element={<AssignTask />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/timesheet",
        element: <PrivateRoute element={<TimeSheet />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/timeline",
        element: <PrivateRoute element={<TimeLine />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/notification",
        element: <PrivateRoute element={<Notification />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/supervisor/workers",
        element: <PrivateRoute element={<WorkersScreen />} fallbackPath="/" />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
    fallbackPath: "/",
  },
]);

export default router;
