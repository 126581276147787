// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBb3_kaBKBowIaS47k48Kr1d38XVkCNMmU",
  authDomain: "tappme-3c2e0.firebaseapp.com",
  projectId: "tappme-3c2e0",
  storageBucket: "tappme-3c2e0.appspot.com",
  messagingSenderId: "437527695659",
  appId: "1:437527695659:web:293e018e93456fba7a756e",
  measurementId: "G-7K3490NV7R",
};
// Get provider IDs

const GoogleAuthProviderID = GoogleAuthProvider.PROVIDER_ID;
console.log("ProvideId-google", GoogleAuthProviderID);
const FacebookAuthProviderID = FacebookAuthProvider.PROVIDER_ID;
console.log("ProvideId-facebook", GoogleAuthProviderID);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const providergl = new GoogleAuthProvider();
const providerfb = new FacebookAuthProvider();

export { auth, providergl, providerfb };
