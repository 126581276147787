import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import HomeApi from "./services/HomeAPI";

const PrivateRoute = ({ element, fallbackPath }) => {
  const isLoggedIn = !!localStorage.getItem("token");

  const navigate = useNavigate();

  // Function to check user status
  const checkUserStatus = async () => {
    if (!isLoggedIn) return;

    try {
      const token = localStorage.getItem("token");
      const jwt = jwtDecode(token);
      const userId = jwt?.id;

      const response = await HomeApi.checkUserStatus(userId);

      if (response.data.data.status === false) {
        handleLogout();
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
      handleLogout();
    }
  };

  // Logout and clear local storage
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);
  };

  const handlePopState = () => {
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    checkUserStatus();

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLoggedIn, navigate]);

  return (
    <Routes>
      <Route
        path="/"
        element={isLoggedIn ? element : <Navigate to={fallbackPath} replace />}
      />
    </Routes>
  );
};

export default PrivateRoute;
