import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { Form, Row, Col, Button, CloseButton } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { jwtDecode } from "jwt-decode";
import ProfileServices from "./services";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const MyProfile = (props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    dob: "",
    citizenship: "",
    ssn: "",
    employeeId: "",
    employmentType: "",
    hireDate: "",
    imageUrl: "",
    languageSkills: [],
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    accountType: "",
    address: "",
    certificates: "",
  });

  const [progress, setProgress] = useState(0);
  const [workforce, setWorkforce] = useState();
  // const [showBar, setShowBar] = useState(false);
  // const [getFileName, setGetFileName] = useState("");
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const fileInputRef = useRef(null);
  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState();
  const [showCertification, setShowCertification] = useState(false);

  const [showBar, setShowBar] = useState(false);
  const [getFileName, setGetFileName] = useState("");
  const saveBtn = useRef(null);
  const [certificatesList, setCertificatesList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileItem, setFileItem] = useState();
  const scriptLoad = useRef(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //fetch profile Id
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const profileId = decodedToken.id;
  console.log("profileId", profileId);

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      props.history.push("/login");
    }
  }, []);

  //fetch profile data

  useEffect(() => {
    getProfile(profileId);
  }, [profileId]);

  const mapProfileData = (data) => {
    const {
      name,
      phoneNo,
      email,
      dob,
      citizenship,
      ssn,
      userId,
      imageUrl,
      employmentType,
      hireDate,
      languageSkills,
      userProfile,
    } = data;

    // Mapping language skills array
    const mappedLanguageSkills = languageSkills.map((skill) => ({
      language: skill.name,
    }));

    // Mapping certificates array directly in userProfile
    userProfile.certificates = userProfile.certificates.map((certificate) => ({
      certificate: certificate.documents.documentUrl,
      certificateName: certificate.certificateName,
    }));

    return {
      userId,
      name,
      phoneNo,
      email,
      dob,
      ssn,
      imageUrl,
      citizenship,
      employmentType,
      hireDate,
      languageSkills: mappedLanguageSkills,
      address: userProfile.address,
      employeeId: userProfile.employeeId,
      employmentType: userProfile.employmentType,
      hireDate: userProfile.hireDate,
      holderName: userProfile.bankDetails.holderName,
      bankName: userProfile.bankDetails.bankName,
      accountNumber: userProfile.bankDetails.accountNumber,
      accountType: userProfile.bankDetails.accountType,
      certificates: userProfile.certificates,
      certificatesName: userProfile.certificateName,
    };
  };

  const getProfile = (profileId) => {
    console.log("profileID:" + profileId);
    ProfileServices.getProfileData(profileId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          console.log("data_53", data);
          console.log("data fetched successfully");
          const mappedData = mapProfileData(data);
          console.log("Json_Mapped_DATA_1", mappedData);
          setFormData(mappedData);
          console.log("certificates", mappedData.certificates);
          // setMessage(response.data.message);
          console.log("response-data-message", response.data.message);
          // setSeverity("success");
          // setOpen(true);
          // setCertificate(mappedData);
          getWorkforce();
        } else {
          setMessage("Error Fetching records");
          setSeverity("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching data:", error);
        setMessage(error.message.data);
        setSeverity("error");
        setOpen(true);
      });
  };
  //------------download Certificates ---------------------------
  // const handleDownload = (certificateUrl) => {
  //   debugger;
  //   console.log("certificateUrl", certificateUrl);
  //   if (!certificateUrl == "") {
  //     const link = document.createElement("a");
  //     link.href = certificateUrl;
  //     link.download = "Certificate"; // You can customize the downloaded file name
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } else {
  //     setMessage("No File Found");
  //     setSeverity("error");
  //     setOpen(true);
  //   }
  // };
  const handleDownload = (certificateUrl) => {
    debugger;
    console.log("certificateUrl", certificateUrl);
    let link; // Declare link variable outside the if block
    if (certificateUrl && certificateUrl !== "") {
      link = document.createElement("a");
      link.href = certificateUrl;
      link.download = "Certificate"; // You can customize the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Show an error message if certificateUrl is empty or falsy
      setMessage("No File Found");
      setSeverity("error");
      setOpen(true);
    }

    // Handle error if the browser fails to download the file
    if (link) {
      link.onerror = () => {
        setMessage("Failed to download the file. Please try again later.");
        setSeverity("error");
        setOpen(true);
      };
    }
  };

  const handleAddCertificate = () => {
    // setShowEditForm;
  };
  function getWorkforce() {
    ProfileServices.getWorkforce(profileId)
      .then((response) => {
        const data = response.data.data;
        console.log("workforce", data);
        setWorkforce(data);
      })
      .catch((error) => {
        setMessage(error.status);
        setSeverity("error");
        setOpen(true);
      });
  }
  //------------------ Languages dropdown for Edit functionality-------------
  const [languages, setLanguages] = useState([]);

  function getlanguages() {
    ProfileServices.languages().then((respons) => {
      setLanguages(respons.data.data);
    });
  }
  useEffect(() => {
    getlanguages();
  }, []);

  //change logo
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    ProfileServices.uploadProfile(formData)
      .then((response) => {
        const data = response.data;
        setMessage(data.message);
        setSeverity("success");
        setOpen(true);
        getProfile(profileId);
        // setImageUrl(file);
        window.location.reload();
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setSeverity("error");
        setOpen(true);
      });
  };

  const handleEdit = () => {
    setShowEditForm(true);
  };
  const handleCancelEdit = () => {
    setShowEditForm(false);
  };

  // destructuring formData
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  //-------------- Certificate functionlity-------------------

  useEffect(() => {
    if (!scriptLoad.current) {
      var script6 = document.createElement("script");
      var script6Code = `  $('.fancy-file-upload').FancyFileUpload({
                params: {
                    action: 'fileuploader'
                },
                maxfilesize: 1000000
            });
        
            $(document).ready(function () {
                $('#image-uploadify').imageuploadify();
            })
        `;
      script6.innerHTML = script6Code;

      document.body.appendChild(script6);

      scriptLoad.current = true;
    }
  }, []);
  // useEffect(() => {
  //    ;

  // }, [show, getFileName, fileName]);

  function uploadDoc(type, datas) {
    // console.log("saveBtn", saveBtn)
    setGetFileName("");
    setShowBar(true);
    setProgress(0);
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        // console.log("percentCompleted", percentCompleted, progressEvent.loaded, progressEvent.total, progressEvent)
      },
    };
    const uploadFile = new FormData();
    uploadFile.append("multipartFile", datas);
    // console.log("uploadDoc", uploadFile)
    if (type == "doc") {
      ProfileServices.fileUpload(uploadFile, config).then((respon) => {
        setShowBar(false);
        // console.log(respon)
        setGetFileName(respon.data.message);
        //saveBtn.current.disabled = false
      });
    } else {
      ProfileServices.imgeUpload(uploadFile, config).then((respon) => {
        setShowBar(false);
        // console.log(respon)
        // setShowEditForm({ ...showEditForm, image: respon.data.message });
        setFormData({ ...showEditForm, image: respon.data.message });
      });
    }
  }
  function addCertificate() {
    setCertificatesList((preData) => [
      ...preData,
      { certificateName: fileName, filename: getFileName },
    ]);
    // setShowEditForm({ ...showEditForm, certificateRequest: certificatesList });
    setFormData({ ...showEditForm, certificateRequest: certificatesList });
    saveBtn.current.disabled = true;

    // console.log(certificatesList)
  }

  //certificate
  // useMemo(() => {
  //   setTimeout(() => {
  //      ;
  //     document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
  //       const dropZoneElement = inputElement.closest(".drop-zone");

  //       dropZoneElement.addEventListener("click", (e) => {
  //         inputElement.click();
  //       });

  //       inputElement.addEventListener("change", (e) => {
  //         if (inputElement.files.length) {
  //           updateThumbnail(dropZoneElement, inputElement.files[0]);
  //         }
  //       });

  //       dropZoneElement.addEventListener("dragover", (e) => {
  //         e.preventDefault();
  //         dropZoneElement.classList.add("drop-zone--over");
  //       });

  //       ["dragleave", "dragend"].forEach((type) => {
  //         dropZoneElement.addEventListener(type, (e) => {
  //           dropZoneElement.classList.remove("drop-zone--over");
  //         });
  //       });

  //       dropZoneElement.addEventListener("drop", (e) => {
  //         e.preventDefault();

  //         if (e.dataTransfer.files.length) {
  //           inputElement.files = e.dataTransfer.files;
  //           updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
  //         }

  //         dropZoneElement.classList.remove("drop-zone--over");
  //       });
  //     });

  //     /**
  //      * Updates the thumbnail on a drop zone element.
  //      *
  //      * @param {HTMLElement} dropZoneElement
  //      * @param {File} file
  //      */
  //     function updateThumbnail(dropZoneElement, file) {
  //        ;
  //       setFileItem(file);
  //       uploadDoc("doc", file);
  //       let thumbnailElement =
  //         dropZoneElement.querySelector(".drop-zone__thumb");

  //       // First time - remove the prompt
  //       if (dropZoneElement.querySelector(".drop-zone__prompt")) {
  //         dropZoneElement.querySelector(".drop-zone__prompt").remove();
  //       }

  //       // First time - there is no thumbnail element, so lets create it
  //       if (!thumbnailElement) {
  //         thumbnailElement = document.createElement("div");
  //         thumbnailElement.classList.add("drop-zone__thumb");
  //         dropZoneElement.appendChild(thumbnailElement);
  //       }

  //       thumbnailElement.dataset.label = file.name;

  //       // Show thumbnail for image files
  //       if (file.type.startsWith("image/")) {
  //         const reader = new FileReader();

  //         reader.readAsDataURL(file);
  //         reader.onload = () => {
  //           thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
  //         };
  //       } else {
  //         thumbnailElement.style.backgroundImage = null;
  //       }
  //     }
  //   }, 501);
  // }, [showCertification]);
  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");

        dropZoneElement.addEventListener("click", (e) => {
          inputElement.click();
        });

        inputElement.addEventListener("change", (e) => {
          if (inputElement.files.length) {
            updateThumbnail(dropZoneElement, inputElement.files[0]);
          }
        });

        dropZoneElement.addEventListener("dragover", (e) => {
          e.preventDefault();
          dropZoneElement.classList.add("drop-zone--over");
        });

        ["dragleave", "dragend"].forEach((type) => {
          dropZoneElement.addEventListener(type, (e) => {
            dropZoneElement.classList.remove("drop-zone--over");
          });
        });

        dropZoneElement.addEventListener("drop", (e) => {
          e.preventDefault();

          if (e.dataTransfer.files.length) {
            inputElement.files = e.dataTransfer.files;
            updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
            if (saveBtn.current && (getFileName == "" || fileName == "")) {
              console.log("inside true condition");
              setIsButtonDisabled(false);
              //console.log("saveBtn", saveBtn.current.disabled)
            } else if (getFileName !== "" && fileName !== "") {
              console.log("inside true condition");
              setIsButtonDisabled(true);
            }
          }

          dropZoneElement.classList.remove("drop-zone--over");
        });
      });
    }, 501);
  }, [showCertification]);

  function updateThumbnail(dropZoneElement, file) {
    setFileItem(file);
    uploadDoc("doc", file);
    let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

    // First time - remove the prompt
    if (dropZoneElement.querySelector(".drop-zone__prompt")) {
      dropZoneElement.querySelector(".drop-zone__prompt").remove();
    }

    // First time - there is no thumbnail element, so lets create it
    if (!thumbnailElement) {
      thumbnailElement = document.createElement("div");
      thumbnailElement.classList.add("drop-zone__thumb");
      dropZoneElement.appendChild(thumbnailElement);
    }

    thumbnailElement.dataset.label = file.name;

    // Show thumbnail for image files
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
      };
    } else {
      thumbnailElement.style.backgroundImage = null;
    }
  }
  function removeCertificate(items) {
    setCertificatesList([]);
    certificatesList.filter((item, i) => {
      const check = item.filename.includes(items.filename);
      if (check == false) {
        setCertificatesList((prevData) => [...prevData, item]);
      }
    });
  }
  // ---------Save Edited details------------
  const nameRef = useRef(null);
  const phoneNoRef = useRef(null);

  //focus on empty fields
  const focusOnFirstEmptyField = () => {
    if (!formData.name) {
      nameRef.current.focus();
    } else if (!formData.phoneNo) {
      phoneNoRef.current.focus();
    }
    // Add logic for other fields as needed
  };

  const handleSaveProfile = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      setMessage("Please fill in all required fields.");
      setSeverity("error");
      setOpen(true);
      // focusOnFirstEmptyField();
    }
    ProfileServices.postProfileData(formData)
      .then((response) => {
        if (response.ok) {
          console.log("Data saved successfully");
          setFormData(response.data);
          setMessage("Data saved successfully");
          setSeverity("success");
          setOpen(true);
        } else {
          console.error("Error while saving data:", response);
          setMessage("Error while saving data.");
          setSeverity("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error while saving data:", error);
        setMessage(error.message);
        setSeverity("error");
        setOpen(true);
      });

    setValidated(true);
  };

  // modal Close function

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //hire date funciton
  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-2 col-lg-2">
              <h5 className="mb-0">{t("My Profile")}</h5>
            </div>

            <Col md={10} lg={10} className="text-right">
              {/* <Button
                variant="outline-secondary"
                className="btn-blue font-14 toggle"
                type="button"
                // style={{ marginRight: "12rem", marginBottom: "2rem " }}
                onClick={handleEdit}
              >
                <i className="fadeIn animated bx bx-pencil"></i> Edit Profile
              </Button> */}
            </Col>
          </div>
        </div>
        <div className="card-body profile">
          <Row className=" mt-1">
            <Col xs={12} md={3} lg={3}>
              <div className="profilePicture">
                <img
                  className="Rectangle32 mb-2"
                  src={
                    imageUrl || formData.imageUrl || "assets/images/profile.png"
                  }
                  alt="Profile"
                />
              </div>
              <br />
              <a
                className="mt-2 text-primary cursor-pointer"
                onClick={() => fileInputRef.current.click()}
              >
                {t("Change_Profile_Picture")}
              </a>
            </Col>

            <input
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleLogoChange}
            />
          </Row>

          {/* <Row className="g-3 align-items-center mb-3 mt-2">
            <label style={{ marginTop: "30px" }}> Personal Information </label>
          </Row> */}

          {showEditForm ? (
            <Form noValidate validated={validated}>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="name">
                    <Form.Control
                      required
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter your name")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="phoneNo">
                    <Form.Control
                      required
                      type="tel"
                      pattern="[0-9]{10}"
                      value={formData.phoneNo}
                      onChange={handleChange}
                      placeholder="Enter your Phone Number"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter a valid phone number")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="address">
                    <Form.Control
                      type="text"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Address"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter a Address")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="email">
                    <Form.Control
                      type="text"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter a Email")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="dob">
                    <Form.Control
                      type="text"
                      value={formatDate(formData.dob)}
                      onChange={handleChange}
                      placeholder="Date of Birth"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Date of Birth")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="citizenship">
                    <Form.Control
                      type="text"
                      value={formData.citizenship}
                      onChange={handleChange}
                      placeholder="Citizenship"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Citizenship")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="ssn">
                    <Form.Control
                      type="text"
                      value={formData.ssn}
                      onChange={handleChange}
                      placeholder="Security Number"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Security number")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="g-3 align-items-center mb-3 mt-2">
                <label>{t("Employment Information")} *</label>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="employeeId">
                    <Form.Control
                      type="text"
                      value={formData.employeeId}
                      onChange={handleChange}
                      placeholder="Employee ID"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Employee ID")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="employmentType">
                    <Form.Control
                      type="text"
                      value={formData.employmentType}
                      onChange={handleChange}
                      placeholder="Employment type"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Employement Type")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="hireDate">
                    <Form.Control
                      type="text"
                      value={formData.hireDate}
                      onChange={handleChange}
                      placeholder="Hire date"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Hire date")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Edit form languages */}

              <Row className="g-3 align-items-center mb-2 mt-2">
                <label>{t("Language Skills")} </label>
              </Row>
              <Row className="g-3 align-items-center mb-3 mt-2">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <Form.Label className="text-dark mb-2">
                      {t("Enter Language")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Stack spacing={3} sx={{ width: 500 }}>
                      <Autocomplete
                        multiple
                        required
                        id="tags-outlined"
                        options={languages}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        onChange={(event, value) => {
                          setFormData({ ...formData, languageSkills: value });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="skills"
                            className="form-control"
                            placeholder="Favorites"
                            required
                          />
                        )}
                      />
                    </Stack>
                    <Form.Control.Feedback type="invalid">
                      {t("Please select at least one language")}.
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Row>

              <Row className="g-3 align-items-center mb-3 mt-2">
                <label>{t("Certificates")}</label>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12} className="mb-3">
                  <div className="card-body card-border align-items-center d-flex justify-content-between">
                    <p className="card-text p-2 mb-0">
                      <span className="text-primary font-18">
                        {t("Site Management Certification")}
                      </span>
                    </p>
                    <button
                      className="btn btn-outline-secondary btn-blue font-14 ms-2 text-end"
                      type="button"
                      onClick={() => setShowCertification(!showCertification)}
                    >
                      {t("Add Certification")}
                    </button>
                  </div>

                  {showCertification ? (
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12 mb3">
                        <div className="card radius-0">
                          <div className="card-body border align-items-center p-lg-5">
                            <div className="col-12 col-md-4 col-lg-4 mb-3">
                              <Form.Group controlId="validationCustom01">
                                <Form.Label className="text-dark mb-2">
                                  {t("License name")}
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder=""
                                  value={fileName}
                                  onChange={(e) => setFileName(e.target.value)}
                                />
                              </Form.Group>
                            </div>

                            <div className="row">
                              <div className="col-xl-6 col-12">
                                <div class="drop-zone">
                                  <span className="drop-zone__prompt">
                                    <img src="/assets/plugins/fancy-file-uploader/fancy_upload.png" />
                                  </span>
                                  <input
                                    type="file"
                                    name="myFile"
                                    class="drop-zone__input"
                                  />
                                </div>
                                <br />
                                {showBar ? (
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                  />
                                ) : null}

                                <Button
                                  type="button"
                                  variant="contained"
                                  // ref={saveBt}
                                  className="btn btn-success mt-2"
                                  onClick={addCertificate}
                                  disabled={isButtonDisabled}
                                >
                                  {t("Add")}
                                </Button>
                              </div>
                            </div>
                            <ul
                              style={{
                                listStyle: "none",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              {certificatesList.map((item, i) => (
                                <li className="">
                                  <div className="card p-3 ">
                                    <div
                                      className="d-flex justify-content-between"
                                      onChange={handleAddCertificate}
                                    >
                                      <span>
                                        <b>{item.certificateName}</b>
                                      </span>{" "}
                                      <span>
                                        <Button
                                          onClick={() =>
                                            removeCertificate(item)
                                          }
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row className="g-3 align-items-center mb-3 mt-1">
                <label>{t("Bank Account Details")} * </label>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="holderName">
                    <Form.Control
                      type="text"
                      required
                      placeholder="Account holder name"
                      value={formData.holderName}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Holder name")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="formBankName">
                    <Form.Control
                      type="text"
                      id="bankName"
                      required
                      placeholder="Bank name"
                      value={formData.bankName}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Bank name")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="accountNumber">
                    <Form.Control
                      type="number"
                      required
                      value={formData.accountNumber}
                      onChange={handleChange}
                      placeholder="Account Details"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Account Details")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="accountType">
                    <Form.Control
                      type="text"
                      id="accountType"
                      required
                      value={formData.accountType}
                      onChange={handleChange}
                      placeholder="Account Type"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Account Type")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <Form.Group controlId="accountType">
                    <Form.Control
                      type="text"
                      id="accountType"
                      required
                      value={formData.accountType}
                      onChange={handleChange}
                      placeholder="Account Type"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please enter Account Type")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="outline-secondary"
                className="btn-blue font-14 toggle"
                type="button"
                style={{ marginRight: "10px" }}
                onClick={handleSaveProfile}
              >
                {t("Save Changes")}
              </Button>
              <Button
                variant="outline-secondary"
                className="btn-blue font-14 toggle"
                type="button"
                onClick={handleCancelEdit}
              >
                {t("Cancel")}
              </Button>
            </Form>
          ) : (
            <Form>
              <div className="row g-3 align-items-center mb-3 mt-2">
                <div className="col-12 col-md-8 col-lg-8 mb-1 w-10">
                  <label className="text-dark">
                    {" "}
                    {t("Personal_Information")}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark ">{t("Name")}</span>
                    <span className="font-14 text-gray">{formData.name}</span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">{t("Phone No")}</span>
                    <span className="font-14 text-gray">
                      {formData.phoneNo}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark ">{t("Address")}</span>
                    <span className="font-14 text-gray">
                      {formData.address.length > 15
                        ? formData.address.substring(0, 15) + "..."
                        : formData.address}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">{t("Email")}</span>
                    <span className="font-14 text-gray">{formData.email}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Date_of_Birth")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.dob}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Citizenship")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.citizenship}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Security_Number")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.ssn}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-items-center mb-3 mt-1">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    {t("Employment_Information")}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Employee_ID")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.employeeId}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Employment_Type")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.employmentType}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">{t("Hire_date")}</span>
                    <span className="font-14 text-gray text-right">
                      {formatDate(formData.hireDate)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-items-center mb-3 mt-1">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">{t("Language_Skills")}</label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3 col-lg-12 mb-4 ">
                  {Array.isArray(formData.languageSkills) &&
                  formData.languageSkills.length > 0 ? (
                    formData.languageSkills.map((skill, index) => (
                      <Chip
                        className="mr-2"
                        key={index}
                        label={skill.language}
                      />
                    ))
                  ) : (
                    <p>{t("No Language Found")}</p>
                  )}
                </div>
              </div>

              <div className="row g-3 align-items-center mb-3 mt-1">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">{t("Certificates")}</label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-lg-12  pb-1 mb-3">
                  <div className="card radius-15">
                    <div className="card-body card-border align-items-center">
                      {Array.isArray(formData.certificates) &&
                      formData.certificates.length > 0 ? (
                        formData.certificates.map((item, index) => (
                          <div key={index} className="row mb-3">
                            <div className="col-12 col-md-6">
                              <span className="text-primary font-18">
                                {item.certificateName}
                              </span>
                            </div>
                            <div className="col-12 col-md-6 text-end">
                              {/* <Link>
                                <FaDownload
                                  className="text-primary font-20 ml-4 cursor-pointer"
                                  onClick={() =>
                                    handleDownload(item.certificate)
                                  }
                                />
                              </Link> */}

                              <a href={item.certificate}>
                                <FileDownloadIcon className="text-primary font-30 ml-4 cursor-pointer" />
                              </a>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>{t("No Certificate Found")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-items-center mb-3 mt-1">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    {t("Bank_Account_Details")}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Account_holder_name")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.holderName}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">{t("Bank_Name")}</span>
                    <span className="font-14 text-gray text-right">
                      {formData.bankName}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Account_Details")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.accountNumber}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span className="font-14 text-dark">
                      {t("Account_Type")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.accountType}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="border-bottom2 justify-content-between ">
                    <span className="font-14 text-dark mb-2">
                      {t("Branch_Address")}
                    </span>
                    <span className="font-14 text-gray text-right">
                      {formData.address.length > 15
                        ? formData.address.substring(0, 15) + "..."
                        : formData.address}
                    </span>
                  </div>
                </div>
              </div>
              {workforce && (
                <div>
                  <div className="row g-3 align-items-center mb-3 mt-1">
                    <div className="col-12 col-md-6 col-lg-6">
                      <label className="text-dark">{t("WorkForce")}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                      <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                        <span className="font-14 text-dark">
                          {t("Workforce_name")}
                        </span>
                        <span className="font-14 text-gray text-right">
                          {workforce.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                      <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                        <span className="font-14 text-dark">
                          {t("Site_Name")}
                        </span>
                        <span className="font-14 text-gray text-right">
                          {workforce.companySites}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                      <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                        <span className="font-14 text-dark">
                          {t("Total_workers")}
                        </span>
                        <span className="font-14 text-gray text-right">
                          {workforce.workers}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          )}
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default MyProfile;
