import React, { useEffect, useState } from "react";
import "./index.css";
import Modal from "react-bootstrap/Modal";
import HomeServices from "./services";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import { formatDateTime } from "../../../../helper/DateFormater";

const SupervisorHome = () => {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  // outer button loader states
  const [approveCheckInLoader, setApproveCheckInLoader] = useState(false);
  const [rejectCheckInLoader, setRejectCheckInLoader] = useState(false);
  const [approveCheckOutLoader, setApproveCheckOutLoader] = useState(false);
  const [rejectCheckOutLoader, setRejectCheckOutLoader] = useState(false);

  //modal buttons loader states
  // const [checkInModalApproveLoader, setCheckInModalApproveLoader] =
  //   useState(false);
  // const [ChekInModalRejectLoader, setChekInModalRejectLoader] = useState(false);
  // const [CheckOutModalApproveLoader, setCheckOutApproveLoader] =
  //   useState(false);
  // const [ChekOutModalRejectLoader, setChekOutModalRejectLoader] =
  //   useState(false);

  const [requests, setRequests] = useState([]); //to update the modal object
  const [flag, setFlag] = useState("");
  const [data, setData] = useState([]);
  const [approvereject, serApproveReject] = useState();
  const [newData, setNewData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadCount, setLoadCount] = useState(0);

  //------------ CheckOut/Check IN Request States---------------
  const [checkInRequests, setCheckInRequests] = useState([]);
  const [checkOutRequests, setCheckOutRequests] = useState([]);

  //--------- fetch supervisorId from token-----------
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const supervisorId = decodedToken.id;
  // const requestId = decodedToken.reqestId;
  // console.log("reqestId", requestId);

  const { t } = useTranslation();

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getHomeDashboard();
      getHomeTimeSheet();
    }
  }, [timeZone]); // This effect runs when timeZone changes

  //--------------- function to fetch Dashboard data based on the supervisorID---------------------
  const getHomeDashboard = async () => {
    try {
      const response = await HomeServices.AllCheckINOUTRequests(
        supervisorId,
        timeZone
      );

      if (response.status === 200) {
        const data = response.data.data;
        console.log("resp_data", data);
        setRequests(data);

        // Separate CHECK-IN and CHECK-OUT requests
        const checkInRequest = data.filter(
          (request) => request.flag === "CHECK-IN"
        );
        const checkOutRequest = data.filter(
          (request) => request.flag === "CHECK-OUT"
        );

        console.log("checkInRequests", checkInRequest);
        console.log("checkOutRequests", checkOutRequest);

        setCheckInRequests(checkInRequest);
        setCheckOutRequests(checkOutRequest);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          console.log("data not found");
          setMessage(error.message);
          setSeverity("error");
        } else if (error.response.status === 500) {
          console.error("Server error", error);
          setMessage(error.message);
          setSeverity("error");
        } else if (error.response.status === 400) {
          console.error("bad request", error);
          setMessage(error.message);
          setSeverity("error");
        } else {
          setMessage(error.message);
          setSeverity("error");
        }
      } else {
        setMessage(error.message);
        setSeverity("error");
      }
    } finally {
      setOpen(false);
    }
  };

  const getHomeTimeSheet = async () => {
    debugger;
    try {
      const supervisorId = decodedToken.id;
      const requestTypeEnum = "";
      const name = "";

      const date = new Date();
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${yyyy}-${mm}-${dd}`;

      const homeTimeSheet = await HomeServices.getHomeTimeSheet(
        supervisorId,
        name,
        formattedDate,
        requestTypeEnum,
        timeZone,
        pageNumber,
        pageSize
      );

      if (homeTimeSheet.status === 200) {
        debugger;
        const data = homeTimeSheet.data.data;
        console.log("data_timesheet_data", data);

        setData(data);

        if (data.length > 0) {
          convertToPercentage(data);
        } else {
          console.log("count", 0);
        }
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 404) {
          console.error("Error 404: Resource not found");
          setMessage("No Timesheet Found");
          setSeverity("error");
          // setOpen(true);
        } else if (status === 500) {
          console.error("Error 500: Internal server error");
        } else if (status === 401) {
          console.error("Error 401: Unauthorized");
        } else {
          console.error("Unexpected error:", error);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const convertToPercentage = (data) => {
    debugger;
    console.log("data_map_12345", data);
    const newData = data.map((item) => {
      console.log("checkinTime", item.checkIn);
      console.log("checkOutTime", item.checkOut);
      console.log("checkinTime", item.totalBreakTime);
      console.log("checkinTime", item.checkInOvertime);
      console.log("checkinTime", item.checkOutOvertime);

      // Extract hours and minutes or set default to "00:00"
      const formatTime = (date) => {
        if (!date) return "00:00";
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      };

      // Parse checkin, checkout, totalBreakTime, overTimeHours to get hours and minutes
      const checkinTime = item.checkIn ? new Date(item.checkIn) : null;
      const checkoutTime = item.checkOut ? new Date(item.checkOut) : null;
      console.log("checkoutTime_1", checkoutTime);
      const totalBreakTime = item.totalBreakTime || "00";
      const checkInOvertime = item.checkInOvertime
        ? new Date(item.checkInOvertime)
        : null;
      const checkOutOvertime = item.checkOutOvertime
        ? new Date(item.checkOutOvertime)
        : null;

      // Prepare final output with hours and minutes combined as "hh:mm"
      const newItem = {
        ...item,
        checkinTime: formatTime(checkinTime),
        checkoutTime: formatTime(checkoutTime),
        totalBreakTime: totalBreakTime,
        checkinOvertime: formatTime(checkInOvertime),
        checkoutOvertime: formatTime(checkOutOvertime),
      };
      return newItem;
    });

    // Set the updated data into state
    setNewData(newData);
    console.log("final_timing_records", newData); // Check the updated data array with combined hours and minutes
  };

  //--- databoard count records-------
  useEffect(() => {
    // getHomeDashboard();
    // getHomeTimeSheet();
    getCountRecords();
  }, []);

  //get timesheet data

  const getCountRecords = () => {
    setLoad(true);
    const workforceId = decodedToken.workforceId;
    HomeServices.getDashboardCount(workforceId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          console.log("resp_data", data);
          setTotalCount(data);
          setLoadCount(1);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
      });
  };

  //modal open functionlity
  const handleOpenModal = (flag) => {
    setFlag(flag); // Set the flag state based on the received flag parameter
    setShowModal(true); // Open the modal
  };

  //-------------------common function for (CEHCKIN/CHECKOUT) Approve/Reject-------------

  const handleApprove = (request, flag) => {
    if (flag === "CHECK-IN") {
      setApproveCheckInLoader(true);
    } else {
      setApproveCheckOutLoader(true);
    }
    const requestType = flag;
    const requestId = request.requestId;
    const status = "APPROVED";

    HomeServices.ApproveRejectRequest(requestType, requestId, status)
      .then((response) => {
        console.log("API response:", response);
        getHomeTimeSheet();
        if (response.status === 200) {
          // Log current check-in requests for debugging
          setRequests((prevData) => {
            console.log("Previous check-in requests:", prevData);

            // Filter out the approved request and log each requestId
            const updatedData = prevData.filter((req) => {
              console.log("Checking requestId:", req.requestId); // Log each requestId
              return req.requestId !== requestId;
            });

            console.log("Updated check-in requests:", updatedData);
            return updatedData;
          });

          // Update the message and UI
          setMessage(response.data.message);
          setSeverity("success");
          setOpen(true);
        } else {
          setMessage("Failed to approve request. Server error.");
          setSeverity("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);
        setMessage(error.message);
        setSeverity("error");
        setOpen(true);
      });
    if (flag === "CHECK-IN") {
      setApproveCheckInLoader(false);
    } else {
      setApproveCheckOutLoader(false);
    }
  };

  const handleReject = (request, flag) => {
    if (flag === "CHECK-IN") {
      setRejectCheckInLoader(true);
    } else {
      setRejectCheckOutLoader(true);
    }
    console.log("request_data_approve", request);
    const requestType = flag;
    console.log("requestType", requestType);
    const requestId = request.requestId;
    console.log("requestId", requestId);
    const status = "DENIED";
    console.log("status", status);
    HomeServices.ApproveRejectRequest(requestType, requestId, status)
      .then((response) => {
        const data = response.data.data;
        if (response.status === 200) {
          getHomeTimeSheet();
          // setCheckInRequests(data);
          setRequests((prevData) => {
            console.log("Previous check-in requests:", prevData);

            // Filter out the approved request and log each requestId
            const updatedData = prevData.filter((req) => {
              console.log("Checking requestId:", req.requestId); // Log each requestId
              return req.requestId !== requestId;
            });

            console.log("Updated check-in requests:", updatedData);
            return updatedData;
          });

          setMessage(response.data.message);
          setSeverity("success");
          setOpen(true);
          // getHomeDashboard(); //to get the updated data on dashaboard
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
      });
    if (flag === "CHECK-IN") {
      setRejectCheckInLoader(false);
    } else {
      setRejectCheckOutLoader(false);
    }
  };

  // function formatDateTime(dateTimeString) {
  //   const dateTime = new Date(dateTimeString);

  //   // Check if the date is valid
  //   if (isNaN(dateTime.getTime())) {
  //     return "Invalid Date";
  //   }

  //   const options = {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //     hourCycle: "h23",
  //   };
  //   let formattedDateTime = dateTime.toLocaleString("en-US", options);
  //   formattedDateTime = formattedDateTime.replace(/,\s*/, "-");
  //   // Convert AM/PM to uppercase
  //   formattedDateTime = formattedDateTime.replace(/\b(?:am|pm)\b/gi, (match) =>
  //     match.toUpperCase()
  //   );

  //   return formattedDateTime;
  //   // return dateTime.toLocaleString("en-GB", options);
  // }

  const onHide = () => {
    setShowModal(false);
  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setShowModal(false);
  };

  const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt.userType;
  const navigate = useNavigate();
  useEffect(() => {
    if (role === "ADMIN") {
      navigate("/dashboard/company/home");
    } else if (role === "SUPERADMIN") {
      navigate("/dashboard/admin/home");
    }
    // else {
    //   navigate("/dashboard/company/home")
    // }
    console.log("Role", role);
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  //function to set AP/PM for 00:00 generated time
  const formatTimeCount = (time) => {
    // Check if time is null or empty
    if (!time) {
      return "00:00";
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(":");

    // Convert hours and minutes to integers
    let hour = parseInt(hours, 10);
    let minute = parseInt(minutes, 10);

    // If hour is NaN or out of range, or minute is NaN or out of range, return "Invalid time"
    if (
      isNaN(hour) ||
      hour < 0 ||
      hour >= 24 ||
      isNaN(minute) ||
      minute < 0 ||
      minute >= 60
    ) {
      return "Invalid time";
    }

    // Determine AM/PM based on the hour
    const ampm = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

    // Pad minutes with leading zero if necessary
    const formattedMinute = minutes.toString().padStart(2, "0");

    // Return formatted time with AM/PM
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* count dashboard */}
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">{t("Total Tasks")}</p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount?.totalTasks
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">{t("Tasks Completed")}</p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount?.totalCompletedTasks
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">{t("Total Workers")}</p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount?.totalWorkers
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">
                    {t("Workers Checked-in")}
                  </p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount?.totalCheckedInWorkerToday
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  check-in /check-out dashboard */}
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="card radius-10 w-100">
            <div class="card-body px-4">
              <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div class="col">
                  <h5 class="mb-0 font-18">{t("Check-In_Requests")}</h5>
                </div>
              </div>
              {Array.isArray(requests) && requests.length > 0
                ? requests
                    .filter((request) => request.flag === "CHECK-IN")
                    .slice(0, 2)
                    .map((request) => (
                      <div key={request.id} className="row">
                        <div className="col-7">
                          <ul className="ps-2 mb-0">
                            <li>
                              <span>
                                {request.name} {t("has requests to")}{" "}
                                <span style={{ color: "#008000" }}>
                                  {request.flag}
                                </span>{" "}
                                <span className="text-primary">
                                  {t("on")}{" "}
                                  {formatDateTime(request.requestTime)}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-5 text-right">
                          {approveCheckInLoader ? (
                            <CircularProgress />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-success2 px-3 px-3"
                              onClick={() => handleApprove(request, "CHECK-IN")}
                              style={{ marginRight: "5px" }}
                            >
                              {t("Approve")}
                            </button>
                          )}
                          {rejectCheckInLoader ? (
                            <CircularProgress />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger2 px-3"
                              onClick={() => handleReject(request, "CHECK-IN")}
                            >
                              {t("Reject")}
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                : null}

              <hr />
              <div class="row text-center mt-4">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal("CHECK-IN")}
                >
                  {t("View_all_Requests")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="card radius-10 w-100">
            <div class="card-body px-4">
              <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div class="col">
                  <h5 class="mb-0 font-18">{t("Check-Out_Requests")}</h5>
                </div>
              </div>
              {Array.isArray(requests) && requests.length > 0
                ? requests
                    .filter((request) => request.flag === "CHECK-OUT")
                    .slice(0, 2)
                    .map((request) => (
                      // Display each request
                      <div key={request.id} className="row">
                        <div className="col-7">
                          <ul className="ps-2 mb-0">
                            <li>
                              <span>
                                {request.name} {t("has requests to")}{" "}
                                <span style={{ color: "#FF1C1C" }}>
                                  {request.flag}
                                </span>{" "}
                                <span className="text-primary">
                                  {t("on")}{" "}
                                  {formatDateTime(request.requestTime)}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-5 text-right">
                          {approveCheckOutLoader ? (
                            <CircularProgress />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-success2 px-3 px-3"
                              onClick={() =>
                                handleApprove(request, "CHECK-OUT")
                              }
                              style={{ marginRight: "5px" }}
                            >
                              {t("Approve")}
                            </button>
                          )}
                          {rejectCheckOutLoader ? (
                            <CircularProgress />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger2 px-3"
                              onClick={() => handleReject(request, "CHECK-OUT")}
                            >
                              {t("Reject")}
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                : null}

              <hr />
              <div class="row text-center mt-4">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal("CHECK-OUT")}
                >
                  {t("View_all_Requests")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Timesheet */}
      <div className="card radius-10 p-4 px-3">
        <div className="card-body height60">
          <div class="row mt-4">
            <div class="col-3 col-md-3">
              <div class="d-flex" style={{ fontWeight: "bold" }}>
                <label class="pe-3 width-40 text-gray">
                  {t("Employee_ID")}
                </label>
                <label class="width-30 text-gray">{t("Name")}</label>
              </div>
            </div>

            <div class="col-9 col-md-9">
              <ul className="table-list">
                <li>{t("Check-In")}</li>
                <li>{t("Break")}</li>
                <li>{t("Check-Out")}</li>
                <li>{t("CheckIn OverTime")}</li>
                <li>{t("CheckOut OverTime")}</li>
              </ul>
            </div>
          </div>
          {newData.length > 0 ? (
            newData
              ?.filter(
                (item, index, self) =>
                  self.findIndex((t) => t.userId === item.userId) === index
              )
              ?.map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-3 col-md-3 py-2">
                    <div className="d-flex">
                      <label className="pe-2 width-33 ms-3">
                        {item?.userId}
                      </label>
                      <label
                        className="width-0"
                        style={{ marginLeft: "3.8rem" }}
                      >
                        {item?.name}
                      </label>
                    </div>
                  </div>
                  <div className="col-9 col-md-9">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12 py-2 ">
                        <ul className="table-list">
                          <li>
                            <Chip
                              label={
                                item?.checkinTime
                                  ? formatTimeCount(item.checkinTime)
                                  : "00:00"
                              }
                              dismissible
                              variant="solid"
                              type="input"
                              className="mr-2"
                              size="lg"
                              style={{ backgroundColor: "#2CDE9A" }}
                            />
                          </li>
                          <li>
                            <Chip
                              label={
                                item?.totalBreakTime
                                  ? `${item.totalBreakTime} min`
                                  : "00"
                              }
                              dismissible
                              variant="solid"
                              type="input"
                              className="mr-2"
                              size="lg"
                              style={{ backgroundColor: "#6DA4FF" }}
                            />
                          </li>
                          <li>
                            <Chip
                              label={
                                item?.checkOut
                                  ? formatTimeCount(item.checkoutTime)
                                  : "00:00"
                              }
                              dismissible
                              variant="solid"
                              type="input"
                              className="mr-2"
                              size="lg"
                              style={{ backgroundColor: "#FF6D6D" }}
                            />
                          </li>
                          <li>
                            <Chip
                              label={
                                item?.checkInOvertime
                                  ? formatTimeCount(item.checkinOvertime)
                                  : "00:00"
                              }
                              dismissible
                              variant="solid"
                              type="input"
                              className="mr-2"
                              size="lg"
                              style={{ backgroundColor: "#FF996D" }}
                            />
                          </li>
                          <li>
                            <Chip
                              label={
                                item?.checkOutOvertime
                                  ? formatTimeCount(item.checkoutOvertime)
                                  : "00:00"
                              }
                              dismissible
                              variant="solid"
                              type="input"
                              className="mr-2"
                              size="lg"
                              style={{ backgroundColor: "#FF996D" }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <p>{t("No_records_found")}</p>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-vcenter"
        centered
        style={{
          width: "60%",
          marginLeft: "20rem",
        }}
      >
        <Modal.Header
          className="text-center"
          onHide={handleModalClose}
          closeButton
        >
          <Modal.Title className="d-flex justify-content-center">
            {flag === "CHECK-OUT"
              ? t("All Check-OUT Requests")
              : t("All Check-IN Requests")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flag === "CHECK-OUT" &&
          checkOutRequests &&
          checkOutRequests.length > 0 ? (
            checkOutRequests.map((request, index) => (
              <div className="row align-items-center">
                <div className="col-md-8">
                  <ul>
                    <li>
                      <p style={{ fontSize: "0.9rem" }}>
                        {request.name} {t("has requests to")}{" "}
                        <span style={{ color: "red" }}>{request.flag}</span>{" "}
                        <span style={{ color: "#1E73FF" }}>{t("on")}</span>{" "}
                        <span style={{ color: "#1E73FF" }}>
                          {formatDateTime(request.requestTime)}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right">
                  {approveCheckOutLoader ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="success"
                      style={{
                        width: "78px",
                        height: "39px",
                        borderRadius: "4px",
                        padding: "14px 20px",
                        opacity: 1,
                        color: "#008000",
                        backgroundColor: "#CBFFC8",
                        marginBottom: "10px",
                        marginRight: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleApprove(request, "CHECK-OUT")}
                    >
                      {t("Approve")}
                    </Button>
                  )}
                  {rejectCheckOutLoader ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="danger"
                      style={{
                        opacity: 1,
                        width: "78px",
                        height: "39px",
                        color: "#FF0000",
                        backgroundColor: "#FFC8C8",
                        marginBottom: "10px",
                        marginRight: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleReject(request, "CHECK-OUT")}
                    >
                      {t("Reject")}
                    </Button>
                  )}
                </div>
              </div>
            ))
          ) : flag === "CHECK-IN" &&
            checkInRequests &&
            checkInRequests.length > 0 ? (
            checkInRequests.map((request, index) => (
              <div className="row align-items-center">
                <div className="col-md-8">
                  <ul>
                    <li>
                      <p style={{ fontSize: "0.9rem" }}>
                        {request.name} {t("has requests to")}{" "}
                        <span
                          style={{
                            color:
                              request.flag === "CHECK-IN" ? "green" : "red",
                          }}
                        >
                          {request.flag}
                        </span>{" "}
                        <span style={{ color: "#1E73FF" }}>{t("on")}</span>{" "}
                        <span style={{ color: "#1E73FF" }}>
                          {formatDateTime(request.requestTime)}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right">
                  {" "}
                  {approveCheckInLoader ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="success"
                      style={{
                        width: "78px",
                        height: "39px",
                        borderRadius: "4px",
                        padding: "14px 20px",
                        opacity: 1,
                        color: "#008000",
                        backgroundColor: "#CBFFC8",
                        marginBottom: "10px",
                        marginRight: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleApprove(request, "CHECK-IN")}
                    >
                      {t("Approve")}
                    </Button>
                  )}
                  {rejectCheckInLoader ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="danger"
                      style={{
                        opacity: 1,
                        width: "78px",
                        height: "39px",
                        color: "#FF0000",
                        backgroundColor: "#FFC8C8",
                        marginBottom: "10px",
                        marginRight: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleReject(request, "CHECK-IN")}
                    >
                      {t("Reject")}
                    </Button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text align-center">{t("No Request Available")}</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>{t("Close")}</Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SupervisorHome;
