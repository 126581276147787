import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Modal } from "react-bootstrap";
import ServerSideTable from "../../../../common components/serverSideTable";
import ReportServices from "./services";
import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Button, Chip, CircularProgress, TextField } from "@mui/material";
import { DataArraySharp } from "@mui/icons-material";

const renderStatusChip = (status) => {
  let color;
  let label;

  switch (status) {
    case "Before":
      color = "warning"; // You can change this to another color if needed
      label = "Before";
      break;
    case "During":
      color = "primary"; // You can change this to another color if needed
      label = "During";
      break;
    case "After":
      color = "success"; // You can change this to another color if needed
      label = "After";
      break;
    default:
      color = "default"; // Default color for any other status
      label = status;
      break;
  }

  return <Chip label={label} color={color} />;
};

const GeneralReport = () => {
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const workforceId = decodetoken.workforceId;

  // defining the States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("All");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [reportData, setReportData] = useState("");
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("id"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const formatDate = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString();
  };

  const [timeZone, setTimeZone] = useState("");
  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getAllReports();
    }
  }, [timeZone]); //

  //table columns
  const columns = [
    {
      field: "reportId",
      headerName: t("Report ID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "etaskReportStatus",
      sortable: false,
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const reportResponse = params?.value;
        return renderStatusChip(reportResponse);
      },
    },
    {
      field: "createdAt",
      headerName: t("Submitted Date"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => formatDate(params.value),
    },

    {
      flex: 1,
      headerName: "Action",
      headerAlign: "center",
      sortable: false,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="medium"
          style={{
            backgroundColor: "#1E73FF",
            color: "#FFFFFF",
            width: "100px",
          }}
          onClick={() => handleModalOpen(params)}
        >
          {t("View")}
        </Button>
      ),
    },
  ];

  const handleModalOpen = (param) => {
    setModalData(param.row);
    console.log("param_general_data", param.row);

    setShow(true);
  };

  const getReportsByStatus = async (status) => {
    setStatus(status);
    setPageNumber(0);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
    setOpen(false);
  };
  const getBeforeAllocation = () => {
    getReportsByStatus("Before");
  };
  const getAllAllocation = () => {
    getReportsByStatus("All");
  };

  const getAfterAllocation = () => {
    getReportsByStatus("After");
  };

  const getDuringAllocation = () => {
    getReportsByStatus("During");
  };
  const getAllReports = () => {
    debugger;
    setLoading(true);
    let eTaskReportStatus = "";
    if (status === "All") {
      eTaskReportStatus = "";
    } else {
      eTaskReportStatus = status;
    }

    ReportServices.getReportsList(
      workforceId,
      eTaskReportStatus,
      name,
      timeZone,
      pageNumber,
      pageSize,
      sortBy,
      sortDir
    )
      .then((res) => {
        if (res.data.data) {
          const { currentPage, totalPages, content, totalElements } =
            res.data.data;
          if (totalElements && totalElements > 0) {
            setTotalItems(totalElements);
            setData(content);
            setCount(totalPages);
            setOpen(true);
          } else {
            setMessage("No data found.");
            setSeverity("info");
            setOpen(true);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          let errorMessage = "An error occurred while processing your request.";
          if (error.response.status === 401) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.status === 400 ||
            error.response.status === 500
          ) {
            errorMessage = error.response.data.message;
          } else if (error.response.status === 404) {
            errorMessage = "No data found.";
            setCount(0);
          }
          setMessage(errorMessage);
          setSeverity("error");
          setOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleImageHover = (imageUrl) => {
    const popupImage = document.getElementById("popupImage");
    popupImage.src = imageUrl;
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "block";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      if (imageLinks[i].getAttribute("href") !== imageUrl) {
        imageLinks[i].style.opacity = "0.5";
      }
    }
  };

  const handleImageLeave = () => {
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "none";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      imageLinks[i].style.opacity = "1";
    }
  };

  useEffect(() => {
    if (page !== undefined) {
      getAllReports();
    }
  }, [page, pageSize, status, sortBy, sortDir]);

  function getCompanies(e) {
    setLoading(false);
    if (e !== undefined) {
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  //download report functionality
  const handleDownloadReport = async () => {
    debugger;
    try {
      const reportID = modalData?.reportId;

      // Constructing static URL
      const downloadUrl = `https://tappme-api.digitalnoticeboard.biz/tappme-api-staging/general/report/download/pdf?reportId=${reportID}&timezoneId=${timeZone}`;

      // Fetch the file
      const response = await fetch(downloadUrl);

      // Check the response status
      if (response.status === 200) {
        // Create an anchor element
        const link = document.createElement("a");

        // Set the href attribute to the download URL
        link.href = downloadUrl;

        // Set the download attribute to specify the filename
        link.download = "case_report.pdf";

        // Simulate a click on the anchor element
        link.click();
      } else if (response.status === 404 || response.status === 500) {
        // Display a notification to the user if file not found or server error
        const responseData = await response.json();
        setMessage(responseData.message);
        setSeverity("error");
        setOpen(true);
      } else {
        // Handle other status codes if needed
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
      setMessage("Error Downloading Report");
      setSeverity("error");
      setOpen(true);
    }
  };

  const [name, setName] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const handleSearch = () => {
    getAllReports();
  };

  function formatDateNew(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;
    return strTime;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-2 col-lg-3">
              <h5 className="mb-0 ">{t("General Reports")}</h5>
            </div>
            <div className="col-12 col-md-9 col-sm-8 col-lg-9">
              <div className="text-right">
                <span className="dropdown1">
                  <button
                    className="btn btn-outlineStyle-secondary dropdown-toggle btn-blue font-14 ml-1"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {status}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end border-b ">
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "All" && "active"
                        }`}
                        onClick={getAllAllocation}
                      >
                        {t("All")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "Before" && "active"
                        }`}
                        onClick={getBeforeAllocation}
                      >
                        {t("Before")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "During" && "active"
                        }`}
                        onClick={getDuringAllocation}
                      >
                        {t("During")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "After" && "active"
                        }`}
                        onClick={getAfterAllocation}
                      >
                        {t("After")}
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3"
          style={{ marginLeft: 10 }}
        >
          <div className="col-12 col-lg-4 col-xl-4">
            <TextField
              fullWidth
              id="name"
              size="small"
              name="name"
              label={t("Search by Name")}
              variant="outlined"
              value={name}
              onChange={onChangeName}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <Button variant="contained" color="primary" onClick={handleSearch}>
              {t("Search")}
            </Button>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <ServerSideTable
              pageSize={pageSize}
              page={page}
              sortBy={sortBy}
              sortDir={sortDir}
              count={count}
              functionName={getCompanies}
              data={[]}
              columns={columns}
              loading={true} // Set loading to true when data is loading
              idname="reportId"
            />
          ) : (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={count > 0 ? data : []}
              columns={columns}
              loading={false}
              idname="reportId"
            />
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header
          // closeButton
          className="border-0 mb-0 p-3 pb-0"
        ></Modal.Header>
        <Modal.Title className="font-20 text-dark text-center  mt-2">
          {t("Report Details")}
        </Modal.Title>

        <Modal.Body className="font-20 text-dark">
          <div className="px-5">
            <div className="border p-4 mb-4 border-20">
              <div className="d-flex mb-3">
                <label htmlFor="exampleDataList" className="form-label">
                  {t("Report ID")} :
                </label>
                <label
                  className="ms-4"
                  style={{
                    backgroundColor: "rgba(109, 164, 255, 1)",
                    color: "white",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  #{modalData?.reportId}
                </label>
              </div>
              <div className="d-flex mb-3">
                <label className="text-blue pe-3 mb-0">
                  <i className="fadeIn animated bx bx-time-five"></i>
                </label>
                <label className="form-label mb-0">
                  {/* Submitted at {modalData.createdAt} <br /> */}
                  {t("Submitted on")} {formatDateNew(modalData.createdAt)}
                  <br />
                  {t("at")} {formatTime(modalData.createdAt)}
                </label>
              </div>
              <div className="d-flex mb-0">
                <label className="text-blue pe-3">
                  <i className="fadeIn animated bx bx-map"></i>
                </label>
                <label className="form-label">{modalData?.location}</label>
              </div>
            </div>
            <div className="row mb-4">
              {/* <div className="col-12 col-md-5">
                 <div className="d-flex">
                  <label className="form-label">Task ID :</label>
                  <label className="form-label ms-4">
                    {modalData.reportId}
                  </label>
                </div> 
              </div>*/}
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">{t("Employee_ID")} :</label>
                  <label className="form-label ms-4">
                    {modalData.employeeId}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">{t("Description")}:</label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.description}
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="row mb-3">
              <div className="col-12 col-md-8">
                <div className="d-flex">
                  <label className="form-label">Report submitted :</label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.workerReportres?.etaskReportStatus} work
                  </label>
                </div>
              </div>
            </div> */}
            <div className="row mb-5">
              <div className="col-12 col-md-12">
                <label className="form-label mb-3">
                  {t("Images attached")}:
                </label>
                <section className="gallery_sec">
                  {modalData?.generalReportDocResponses?.map((doc, index) => (
                    <a
                      data-fancybox="gallery"
                      href={doc?.documentUrl}
                      key={index}
                      className="image-link"
                      onMouseEnter={() => handleImageHover(doc.documentUrl)}
                      onMouseLeave={handleImageLeave}
                    >
                      <img
                        src={doc?.documentUrl}
                        className="zoom-img"
                        alt={"image"}
                      />
                    </a>
                  ))}
                </section>
              </div>
            </div>
            <div id="imagePopup" style={{ display: "none" }}>
              <img id="popupImage" src="" alt="popup" />
            </div>

            <div className="border-0">
              <div className="col-12 mb-3">
                <a
                  class="btn btn-primary py-3 px-4"
                  onClick={handleDownloadReport}
                >
                  {t("Download Report")}
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="contained" onClick={handleClose}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack> */}
    </>
  );
};

export default GeneralReport;
