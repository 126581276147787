import React, { useState, useEffect } from "react";
import TimeSheetServices from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";

const TimeSheet = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("All");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(100);
  const [newData, setNewData] = useState([]);
  const [date, setDate] = useState(getCurrentDateInput());
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getTimeSheet();
    }
  }, [timeZone]); // This effect runs when timeZone changes

  //handling the filter state
  const handleStatus = (status) => {
    setStatus(status);
    console.log("status", status);
  };

  //function to get timesheet records
  const getTimeSheet = () => {
    debugger;
    console.log("date_1234", date);
    const supervisorId = decodedToken.id;
    let requestTypeEnum = "";
    const name = "";
    // const f_date = filterDate(date);
    const f_date = date;
    console.log("f_date : " + f_date);
    const status = "";
    console.log("status", status);
    TimeSheetServices.getTimeSheetData(
      supervisorId,
      name,
      f_date,
      requestTypeEnum,
      timeZone,
      pageNumber,
      pageSize
    )
      .then((res) => {
        const timeSheetResponse = res;
        console.log("timesheet_res", timeSheetResponse);
        if (timeSheetResponse.status === 200) {
          console.log("timesheet_res", timeSheetResponse.data.data);
          // setData((prevData) => [...prevData, timeSheetResponse.data.data]);
          setData(timeSheetResponse.data.data);
          console.log("data", data);
          convertToPercentage(timeSheetResponse.data.data);
          setCount(1);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
            setCount(0);
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const convertToPercentage = (data) => {
    console.log("data_map", data);
    const newData = data.map((item) => {
      console.log("checkinTime", item.checkIn);
      console.log("checkinTime", item.checkOut);
      console.log("checkinTime", item.totalBreakTime);
      console.log("checkinTime", item.checkInOvertime);
      console.log("checkinTime", item.checkOutOvertime);

      // Extract hours and minutes or set default to "00:00"
      const formatTime = (date) => {
        if (!date) return "00:00";
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      };

      // Parse checkin, checkout, totalBreakTime, overTimeHours to get hours and minutes
      const checkinTime = item.checkIn ? new Date(item.checkIn) : null;
      const checkoutTime = item.checkOut ? new Date(item.checkOut) : null;
      const totalBreakTime = item.totalBreakTime || "00:00";
      const checkInOvertime = item.checkInOvertime
        ? new Date(item.checkInOvertime)
        : null;
      const checkOutOvertime = item.checkOutOvertime
        ? new Date(item.checkOutOvertime)
        : null;

      // Prepare final output with hours and minutes combined as "hh:mm"
      const newItem = {
        ...item,
        checkinTime: formatTime(checkinTime),
        checkoutTime: formatTime(checkoutTime),
        totalBreakTime: totalBreakTime,
        checkinOvertime: formatTime(checkInOvertime),
        checkoutOvertime: formatTime(checkOutOvertime),
      };

      return newItem;
    });

    // Set the updated data into state
    setNewData(newData);
    console.log("setNewData_item_data", newData); // Check the updated data array with combined hours and minutes
  };

  const handleDownloadTimeline = async () => {
    debugger;
    try {
      console.log(date);
      const supervisorId = decodedToken.id;
      // Convert date to the proper format if needed
      const selectedDate = new Date(date); // Convert the string to a Date object
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = selectedDate.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`; // Format the date as YYYY-MM-DD

      console.log(formattedDate);

      // Construct the URL for downloading the report
      const downloadUrl = `https://tappme-api.digitalnoticeboard.biz/tappme-api-staging/timesheet/export-to-excel?supervisorId=${supervisorId}&date=${date}`;

      // Fetch the file
      const response = await fetch(downloadUrl);

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // Create an anchor element
        const link = document.createElement("a");

        // Set the href attribute to the download URL
        link.href = downloadUrl;

        // Set the download attribute to specify the filename with .xlsx extension
        link.download = "case_report.xlsx";

        // Simulate a click on the anchor element
        link.click();
      } else if (response.status === 404) {
        // Display a notification to the user if file not found
        setMessage(response.data.message);
        setSeverity("error");
        setOpen(true);
      } else if (response.status === 500) {
        // Display a notification to the user if file not found
        setMessage(response.data.message);
        setSeverity("error");
        setOpen(true);
      } else {
        // Handle other status codes if needed
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
      setMessage("Error Downloading Report");
      setSeverity("error");
      setOpen(true);
    }
  };

  useEffect(() => {
    getTimeSheet();
  }, [date]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Function to get today's date in the format DD-MM-YYYY
  function getCurrentDateInput() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  //function to set AP/PM for 00:00 generated time
  // const formatTimeCount = (time) => {
  //   // Check if time is null or empty
  //   if (!time) {
  //     return "00:00";
  //   }

  //   // Split the time string into hours and minutes
  //   const [hours, minutes] = time.split(":");

  //   // Convert hours to integer
  //   let hour = parseInt(hours, 10);

  //   // If hour is NaN or null, or hour is 0, return blank string
  //   if (isNaN(hour) || hour === 0) {
  //     const ampm = parseInt(minutes, 10) >= 720 ? "AM" : "PM";
  //     return `00:${minutes} ${ampm}`;
  //   }
  //   // Determine AM/PM based on the hour
  //   const ampm = hour >= 12 ? "PM" : "AM";
  //   // Convert hour to 12-hour format
  //   hour = hour % 12 || "00";

  //   // Return formatted time with AM/PM

  //   // If minutes are provided, return formatted time with AM/PM

  //   return `${hour}:${minutes} ${ampm}`;
  // };

  const formatTimeCount = (time) => {
    // Check if time is null or empty
    if (!time) {
      return "00:00";
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(":");

    // Convert hours and minutes to integers
    let hour = parseInt(hours, 10);
    let minute = parseInt(minutes, 10);

    // If hour is NaN or out of range, or minute is NaN or out of range, return "Invalid time"
    if (
      isNaN(hour) ||
      hour < 0 ||
      hour >= 24 ||
      isNaN(minute) ||
      minute < 0 ||
      minute >= 60
    ) {
      return "Invalid time";
    }

    // Determine AM/PM based on the hour
    const ampm = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

    // Pad minutes with leading zero if necessary
    const formattedMinute = minutes.toString().padStart(2, "0");

    // Return formatted time with AM/PM
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-12 col-md-2 col-lg-2">
              <h5 class="mb-0">{t("Timesheet")}</h5>
            </div>
            <div class="col-6 col-md-4 col-lg-4 icon-blue">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                class="form-control blue-border"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="text-right">
                <Link
                  className="btn btn-primary font-14"
                  to={{
                    pathname: "/dashboard/supervisor/timeline",
                    // state: {
                    //   data: param.row,
                    //   view: true,
                    // },
                  }}
                >
                  {t("View My Timeline")}
                </Link>
                <span class="dropdown1">
                  <button
                    class="btn btn-primary font-14 ms-3"
                    type="button"
                    onClick={handleDownloadTimeline}
                  >
                    {t("Export Data")}{" "}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body height60">
          <div class="row mt-4">
            <div class="col-3 col-md-3">
              <div class="d-flex" style={{ fontWeight: "bold" }}>
                <label class="pe-3 width-40 text-gray">
                  {t("Employee_ID")}
                </label>
                <label class="width-30 text-gray">{t("Name")}</label>
              </div>
            </div>

            <div class="col-9 col-md-9">
              <ul className="table-list">
                <li>{t("Check-In")}</li>
                <li>{t("Break")}</li>
                <li>{t("Check-Out")}</li>
                <li>{t("CheckIn OverTime")}</li>
                <li>{t("CheckOut OverTime")}</li>
              </ul>
            </div>
          </div>

          {count > 0 ? (
            newData
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.userId === item.userId) === index
              ) // Filter out duplicates based on userId
              .map((item, index) => (
                <div key={index} className="row align-items-center">
                  <div className="col-3 col-md-3 py-2">
                    <div className="d-flex">
                      <label className="pe-2 width-33 ms-3">
                        {item?.userId}
                      </label>
                      <label
                        className="width-0"
                        style={{ marginLeft: "3.8rem" }}
                      >
                        {item?.name}
                      </label>
                    </div>
                  </div>
                  <div className="col-9 col-md-9 py-2">
                    <ul className="table-list">
                      <li>
                        <Chip
                          label={
                            item?.checkinTime
                              ? formatTimeCount(item.checkinTime)
                              : "00:00"
                          }
                          dismissible
                          variant="solid"
                          type="input"
                          className="mr-2"
                          size="lg"
                          style={{ backgroundColor: "#2CDE9A" }}
                        />
                      </li>
                      <li>
                        <Chip
                          label={
                            item?.totalBreakTime
                              ? `${item.totalBreakTime} min`
                              : "00:00"
                          }
                          dismissible
                          variant="solid"
                          type="input"
                          className="mr-2"
                          size="lg"
                          style={{ backgroundColor: "#6DA4FF" }}
                        />
                      </li>
                      <li>
                        {" "}
                        <Chip
                          label={
                            item?.checkOut
                              ? formatTimeCount(item.checkoutTime)
                              : "00:00"
                          }
                          dismissible
                          variant="solid"
                          type="input"
                          className="mr-2"
                          size="lg"
                          style={{ backgroundColor: "#FF6D6D" }}
                        />
                      </li>
                      <li>
                        {" "}
                        <Chip
                          label={
                            item?.checkInOvertime
                              ? formatTimeCount(item.checkinOvertime)
                              : "00:00"
                          }
                          checkoutTime
                          dismissible
                          variant="solid"
                          type="input"
                          className="mr-2"
                          size="lg"
                          style={{ backgroundColor: "#FF996D" }}
                        />
                      </li>
                      <li>
                        {" "}
                        <Chip
                          label={
                            item?.checkOutOvertime
                              ? formatTimeCount(item.checkoutOvertime)
                              : "00:00"
                          }
                          dismissible
                          variant="solid"
                          type="input"
                          className="mr-2"
                          size="lg"
                          style={{ backgroundColor: "#FF996D" }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <p>{t("No_records_found")}</p>
            </div>
          )}
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default TimeSheet;
