import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TimeSheetServices from "./services";
import ProgressBar from "react-customizable-progressbar";
import { jwtDecode } from "jwt-decode";
import { format, parseISO, differenceInSeconds } from "date-fns";
import { Link } from "react-router-dom";

const TimeLine = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("All");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(100);
  const [newData, setNewData] = useState([]);
  const [filter, setFilter] = useState("Yesterday");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [overtimeHours, setOvertimeHours] = useState("");
  const [overtimeMinutes, setOvertimeMinutes] = useState("");
  const [checkInTime, setCheckInTime] = useState(0);
  const [shiftEndHours, setShiftEndHours] = useState(0);
  const [chartData, setChartData] = useState();
  const [normalHours, setNoramlHours] = useState(0);
  const [overTimeHours, setOverTimeHours] = useState(0);
  const { t } = useTranslation();

  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const token = localStorage.getItem("token");

  const decodedToken = jwtDecode(token);
  const chartRef = useRef(null);
  //handling the filter state

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  console.log("hours", hours);
  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getShiftHours();
    }
  }, [timeZone]); // This effect runs when timeZone changes

  const handleStatus = (status) => {
    setStatus(status);
    console.log("status", status);
  };
  const handlefilter = (filter) => {
    setFilter(filter);
  };

  //get timeline piChart making functional call of TimelineChart into getTimeLinechartData
  const getTimeLinechartData = () => {
    debugger;
    // const workforceId = 190; //for testing
    const workerId = decodedToken?.id;
    console.log("workforceId: " + workerId);
    TimeSheetServices.getTimeLineChart(workerId, filter)
      .then((res) => {
        const timeLineResponse = res.data.data;
        if (timeLineResponse) {
          setHours(timeLineResponse.totalHours.hours);
          setMinutes(timeLineResponse.totalHours.minutes);
          setOvertimeHours(timeLineResponse.overtimeHours.hours);
          setOvertimeMinutes(timeLineResponse.overtimeHours.minutes);
          TimeLineChart(timeLineResponse); // Pass the data to TimeLineChart

          console.log("timeline_response", timeLineResponse);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  // const TimeLineChart = (timeLineData) => {
  //   const normalHoursPercentage =
  //     parseFloat(timeLineData.normalHours.percentage) || 0;
  //   const overTimePercentage =
  //     parseFloat(timeLineData.overtimeHours.percentage) || 0;
  //   // Update the state with the calculated percentages
  //   setNoramlHours(normalHoursPercentage);
  //   setOverTimeHours(overTimePercentage);
  //   console.log("chartData", chartData);
  // };

  const TimeLineChart = (timeLineData) => {
    // Parse the percentages and ensure they are numbers
    const normalHoursPercentage = Math.min(
      parseFloat(timeLineData.normalHours.percentage) || 0,
      100
    );
    const overTimePercentage = Math.min(
      parseFloat(timeLineData.overtimeHours.percentage) || 0,
      100
    );

    // Update the state with the calculated percentages
    setNoramlHours(normalHoursPercentage);
    setOverTimeHours(overTimePercentage);

    console.log("normalHoursPercentage", normalHoursPercentage);
    console.log("overTimePercentage", overTimePercentage);
  };

  //function to get timeline records
  const getTimeLineData = () => {
    debugger;
    console.log("decoded_token", decodedToken);
    const userId = decodedToken.id;
    let requestTypeEnum = "";
    const fromDate = "";
    const toDate = "";

    console.log("status", status);
    TimeSheetServices.getTimeLineData(
      userId,
      fromDate,
      toDate,
      requestTypeEnum,
      pageNumber,
      pageSize
    )
      .then((res) => {
        const timeLineResponse = res;
        console.log("timesheet_res", timeLineResponse);
        if (timeLineResponse.status === 200) {
          const data = timeLineResponse.data.data;
          console.log("timsheet_data", data);
          if (data.length > 0) {
            const lastIndexData = data[data.length - 1]; // Get the last index data
            setData(lastIndexData);
            console.log("checkin_time", lastIndexData);
            convertToPercentage(lastIndexData);
            setCount(1);
          } else {
            setData(data); // Set empty array if data is empty
            console.log("checkin_time", data);
            convertToPercentage(data);
            setCount(0);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
            setCount(0);
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  //get the percentages from the reponse data
  const convertToPercentage = (data) => {
    console.log("data_map", data);
    const newData = data.map((item) => {
      console.log("checkinTime", item.checkIn);
      console.log("checkinTime", item.checkOut);
      console.log("checkinTime", item.totalBreakTime);
      console.log("checkinTime", item.checkInOvertime);
      console.log("checkinTime", item.checkOutOvertime);

      // Extract hours and minutes or set default to "00:00"
      const formatTime = (date) => {
        if (!date) return "00:00";
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      };

      // Parse checkin, checkout, totalBreakTime, overTimeHours to get hours and minutes
      const checkinTime = item.checkIn ? new Date(item.checkIn) : null;
      const checkoutTime = item.checkOut ? new Date(item.checkOut) : null;
      const totalBreakTime = item.totalBreakTime || "00:00";
      const checkInOvertime = item.checkInOvertime
        ? new Date(item.checkInOvertime)
        : null;
      const checkOutOvertime = item.checkOutOvertime
        ? new Date(item.checkOutOvertime)
        : null;

      // Prepare final output with hours and minutes combined as "hh:mm"
      const newItem = {
        ...item,
        checkinTime: formatTime(checkinTime),
        checkoutTime: formatTime(checkoutTime),
        totalBreakTime: totalBreakTime,
        checkInOvertime: formatTime(checkInOvertime),
        checkOutOvertime: formatTime(checkOutOvertime),
      };

      return newItem;
    });

    // Set the updated data into state
    setNewData(newData);
    console.log("setNewData_item_data", newData); // Check the updated data array with combined hours and minutes
  };

  const getShiftHours = () => {
    debugger;
    const userId = decodedToken.id;
    // const userId = 190; //for testing
    const date = new Date();
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    TimeSheetServices.getShiftHoursData(userId, formattedDate, timeZone)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const checkInDate = new Date(data.checkIn);
          const formattedTime = checkInDate.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          setCheckInTime(formattedTime);
          setShiftEndHours(hours);

          const newDueDate = format(parseISO(data.checkIn), "HH:mm:ss");
          const currentSystemTime = format(new Date(), "HH:mm:ss");

          const dueTime = parseISO(`1970-01-01T${newDueDate}`);
          const systemTime = parseISO(`1970-01-01T${currentSystemTime}`);

          const duration = differenceInSeconds(systemTime, dueTime);
          const totalHours = Math.floor(duration / 3600);
          setTotalHours(totalHours);
          const totalMinutes = Math.floor((duration % 3600) / 60);
          setTotalMinutes(totalMinutes);
          const totalSeconds = duration % 60;
          setTotalSeconds(totalSeconds);

          const shiftDuration = 9 * 3600 + 30 * 60;
          const remainingDuration = Math.max(shiftDuration - duration, 0);

          const remainingHours = Math.floor(remainingDuration / 3600);
          setRemainingHours(remainingHours);
          const remainingMinutes = Math.floor((remainingDuration % 3600) / 60);
          setRemainingMinutes(remainingMinutes);
          const remainingSeconds = remainingDuration % 60;
          setRemainingSeconds(remainingSeconds);

          console.log("Shift_end_hours", hours);
        } else {
          // Handle other successful responses
          console.log("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
          } else if (status === 400) {
            console.error("Error 400: Bad request");
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  //default call
  useEffect(() => {
    getTimeLineData();
  }, [status]);

  useEffect(() => {
    getShiftHours();
  }, []);

  useEffect(() => {
    getTimeLinechartData();
  }, [filter]);

  //function to set AP/PM for 00:00 generated time
  const formatTimeCount = (time) => {
    // Check if time is null or empty
    if (!time) {
      return "00:00";
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(":");
    // Convert hours to integer
    let hour = parseInt(hours, 10);

    // If hour is NaN or null, or hour is 0, return blank string
    if (isNaN(hour) || hour === 0) {
      return "00:00";
    }
    // Determine AM/PM based on the hour
    const ampm = hour >= 12 ? "PM" : "AM";
    // Convert hour to 12-hour format
    hour = hour % 12 || "00";
    // Return formatted time with AM/PM
    return `${hour}:${minutes || "00"} ${ampm}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-12 col-md-2 col-lg-2">
              <h5 class="mb-0">{t("My Timeline")}</h5>
            </div>

            <div class="col-12 col-md-5 col-lg-5"></div>
            <div class="col-12 col-md-5 col-lg-5 text-end">
              <Link
                to="/dashboard/supervisor/timesheet"
                className="btn btn-outline-secondary btn-blue font-14 ms-5"
              >
                {t("Back")}
              </Link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="border radius-10 p-4 p-lg-4 mt-1">
            <h5>{t("Today Working Hours")}</h5>
            <div class="row align-items-center d-flex">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="border radius-10 p-4 p-lg-4 mt-4 text-center">
                  <p class="text-blue">
                    {t("Logged in today at")} {checkInTime}
                  </p>
                  <div className="text-bold" style={{ fontSize: "20px" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {totalHours}
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {totalMinutes}
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {totalSeconds}
                    </span>
                  </div>

                  <p class="text-blue mt-3">
                    {t("Shift end")} {remainingHours}:{remainingMinutes}{" "}
                    {t("hours")}
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-6 pt-2">
                <div class="border radius-10 p-4 p-lg-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{ marginBottom: "10px", fontWeight: 900 }}>
                      {t("Hours History")}
                    </p>

                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <div className="">
                        <button
                          class="btn btn-outlineStyle-secondary dropdown-toggle btn-blue px-3 me-5"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {filter}
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end border-b">
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("Yesterday")}
                            >
                              {t("Yesterday")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("ThisWeek")}
                            >
                              {t("This Week")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("LastWeek")}
                            >
                              {t("Last Week")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("ThisMonth")}
                            >
                              {t("This Month")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("LastMonth")}
                            >
                              {t("Last Month")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex me-3">
                          <div
                            className="indicator-circle mt-1"
                            style={{
                              backgroundColor: "#1E73FF",
                              borderRadius: "50%",
                              width: "10px",
                              height: "10px",
                            }}
                          ></div>
                          <span className="ms-2">
                            {t("Normal Working Hours")}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex me-3">
                          <div
                            className="indicator-circle overtime mt-1"
                            style={{
                              backgroundColor: "#FF996D",
                              borderRadius: "50%",
                              width: "10px",
                              height: "10px",
                            }}
                          ></div>
                          <span className="ms-2">
                            {t("Overtime Working Hours")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <ProgressBar
                      className="first-circle mx-auto"
                      radius={100}
                      progress={normalHours}
                      strokeColor="#1E73FF"
                      pointerRadius={3}
                      pointerStrokeWidth={0}
                      strokeWidth={20}
                      trackStrokeWidth={20}
                      pointerStrokeColor="#7bcd5c"
                      initialAnimationDelay={1000}
                      initialAnimation={true}
                      trackTransition=".1s ease"
                      transition="1s ease"
                    >
                      <div className="indicator second-circle1">
                        <ProgressBar
                          radius={60}
                          className="second-circle"
                          progress={overTimeHours}
                          strokeWidth={10}
                          trackStrokeWidth={10}
                          strokeColor="#FF996D"
                          pointerRadius={3}
                          pointerStrokeWidth={0}
                          pointerStrokeColor="#7bcd5c"
                          initialAnimationDelay={1000}
                          initialAnimation={true}
                          trackTransition=".1s ease"
                          transition="1s ease"
                        />
                        <span className="text-a">
                          {t("Working")} <br />
                          {t("hours")}
                        </span>
                      </div>
                    </ProgressBar>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {t("Total Working Hours")} : {hours !== "" ? hours : 0}{" "}
                    {t("hours")} & {minutes !== "" ? minutes : 0} minutes
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {t("Total Overtime Hours")} :{" "}
                    {overtimeHours !== "" ? overtimeHours : 0} {t("hours")} &{" "}
                    {overtimeMinutes !== "" ? overtimeMinutes : 0}{" "}
                    {t("minutes")}
                  </div>
                </div>
              </div>

              {/* <div class="row mt-4">
                <div class="col-3 col-md-3 pt-2">
                  <div class="d-flex">
                    <label class="pe-3 width-40 text-gray">
                      {" "}
                      {t("Employee ID")}
                    </label>
                    <label class="width-60 text-gray">{t("Name")}</label>
                  </div>
                </div>

                <div class="col-9 col-md-9">
                  <ul className="table-list">
                    <li>Check-In</li>
                    <li>Break</li>
                    <li>Check-Out</li>
                    <li>CheckIn OverTime</li>
                    <li>CheckOut OverTime</li>
                  </ul>
                </div>
              </div>
              {count > 0
                ? newData
                    .filter(
                      (item, index, self) =>
                        self.findIndex((t) => t.userId === item.userId) ===
                        index
                    ) // Filter out duplicates based on userId
                    .map((item, index) => (
                      <div key={index} className="row align-items-center">
                        <div className="col-3 col-md-3">
                          <div className="d-flex">
                            <label className="pe-2 width-33 ms-3">
                              {item?.userId}
                            </label>
                            <label
                              className="width-0"
                              style={{ marginLeft: "3.8rem" }}
                            >
                              {item?.name}
                            </label>
                          </div>
                        </div>
                        <div className="col-9 col-md-9 py-2">
                          <ul className="table-list">
                            <li>
                              <Chip
                                label={
                                  item?.checkinTime
                                    ? formatTimeCount(item.checkinTime)
                                    : "00:00"
                                }
                                dismissible
                                variant="solid"
                                type="input"
                                className="mr-2"
                                size="lg"
                                style={{ backgroundColor: "#2CDE9A" }}
                              />
                            </li>
                            <li>
                              <Chip
                                label={
                                  item?.totalBreakTime
                                    ? `${item.totalBreakTime} min`
                                    : "00:00"
                                }
                                dismissible
                                variant="solid"
                                type="input"
                                className="mr-2"
                                size="lg"
                                style={{ backgroundColor: "#6DA4FF" }}
                              />
                            </li>
                            <li>
                              {" "}
                              <Chip
                                label={
                                  item?.checkoutTime
                                    ? formatTimeCount(item.checkoutTime)
                                    : "00:00"
                                }
                                dismissible
                                variant="solid"
                                type="input"
                                className="mr-2"
                                size="lg"
                                style={{ backgroundColor: "#FF6D6D" }}
                              />
                            </li>
                            <li>
                              {" "}
                              <Chip
                                label={
                                  item?.overTimeHours
                                    ? formatTimeCount(item.checkInOvertime)
                                    : "00:00"
                                }
                                checkoutTime
                                dismissible
                                variant="solid"
                                type="input"
                                className="mr-2"
                                size="lg"
                                style={{ backgroundColor: "#FF996D" }}
                              />
                            </li>
                            <li>
                              {" "}
                              <Chip
                                label={
                                  item?.overTimeHours
                                    ? formatTimeCount(item.checkOutOvertime)
                                    : "00:00"
                                }
                                dismissible
                                variant="solid"
                                type="input"
                                className="mr-2"
                                size="lg"
                                style={{ backgroundColor: "#FF996D" }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                : null} */}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-content">
            <div class="modal-header border-0 mb-0 p-3 pb-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body font-20 text-dark px-4">
              <p class="font-18 text-dark  text-center mb-5">
                <i class="fadeIn animated bx bx-arrow-back pe-3"></i> Request
                for going outside the geofence from{" "}
                <span class="text-blue">David (#LA0568)</span>
              </p>
              <div class="row">
                <div class="col-12 mb-3">
                  <label class="form-label">Reason</label>
                  <textarea
                    class="form-control"
                    rows="4"
                    cols="4"
                    placeholder="Need to get glue to fix the roof ceiling of the new construction site ASAP "
                  ></textarea>
                  <div class="mt-4">
                    <button
                      type="button"
                      class="btn btn-success2 px-3 py-3 w-100 mb-3 font-15"
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger2 px-3 py-3 w-100 mb-3 font-15"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeLine;
