import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getProfileData = (profileId) => {
  debugger;
  return http.get(`/user/get/${profileId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const postProfileData = (data) => {
  debugger;
  return http.put("user/update", data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};
const fileUpload = (data, config) => {
  debugger;
  return http.post(`upload/doc`, data, config, {
    headers: {
      ...authHeader(),
    },
  });
};
const uploadProfile = (formData) => {
  debugger;
  return http.put(`user/profile/photo`, formData, {
    headers: {
      ...authHeader(),
    },
  });
};

const imgeUpload = (data, config) => {
  debugger;
  return http.put(`upload/image`, data, config, {
    headers: {
      ...authHeader(),
    },
  });
};
const languages = () => {
  return http.get(`application/worker/languages`);
};
//get workforce details
const getWorkforce = (supervisorId) => {
  return http.get(`workforce/worker/${supervisorId}`);
};
const ProfileServices = {
  getProfileData,
  postProfileData,
  fileUpload,
  imgeUpload,
  languages,
  getWorkforce,
  uploadProfile,
};

export default ProfileServices;
