import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

//get all chats
const postUserMessage = (companyId) => {
  return http.get(`/chat/support/chat/${companyId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const ChatSupportServices = {
  postUserMessage,
};

export default ChatSupportServices;
