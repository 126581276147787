import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Modal } from "react-bootstrap";
import ServerSideTable from "../../../../common components/serverSideTable";
import ReportServices from "./services";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Define a function to render the status Chip
const renderStatusChip = (status) => {
  let color;
  let label;

  switch (status) {
    case "Before":
      color = "warning"; // You can change this to another color if needed
      label = "Before";
      break;
    case "During":
      color = "primary"; // You can change this to another color if needed
      label = "During";
      break;
    case "After":
      color = "success"; // You can change this to another color if needed
      label = "After";
      break;
    default:
      color = "default"; // Default color for any other status
      label = status;
      break;
  }

  return <Chip label={label} color={color} />;
};

const TaskReport = () => {
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const workforceId = decodetoken.workforceId;

  // defining the States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("All");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [dateTime, setDateTime] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("id"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getAllReports();
    }
  }, [timeZone]); //

  //table columns
  const columns = [
    {
      field: "reportId",
      headerName: t("Report ID"),
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const reportResponse = params?.row?.workerReportResponse;
        if (reportResponse && reportResponse.reportId) {
          return reportResponse.reportId;
        }
        return "num";
      },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "priority",
      headerName: t("Priority"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor:
              params.value === "High"
                ? "#FF0000"
                : params.value === "Low"
                ? "#0ACF83"
                : "#E5B304",
            width: "100px",
            fontSize: "12px",
          }}
          size="small"
        >
          {params.value}
        </Button>
      ),
    },
    // {
    //   field: "employeeId",
    //   headerName: t("Employee ID"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "etaskReportStatus",
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const reportResponse = params?.row?.workerReportResponse;
        const status = reportResponse
          ? reportResponse.etaskReportStatus
          : "num";
        return renderStatusChip(status);
      },
    },
    {
      field: "startDate",
      headerName: t("Start Date"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dueDate",
      headerName: t("Due Date"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      flex: 1,
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="medium"
          style={{
            backgroundColor: "#1E73FF",
            color: "#FFFFFF",
            width: "100px",
          }}
          onClick={() => handleModalOpen(params)}
        >
          {t("View")}
        </Button>
      ),
    },
  ];

  const handleModalOpen = (param) => {
    setModalData(param.row);
    console.log("modal_data", param.row);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const getReportsByStatus = async (status) => {
    setStatus(status);
    setPageNumber(0);
  };

  const getAllAllocation = () => {
    getReportsByStatus("All");
  };
  const getBeforeAllocation = () => {
    getReportsByStatus("Before");
  };

  const getAfterAllocation = () => {
    getReportsByStatus("After");
  };

  const getDuringAllocation = () => {
    getReportsByStatus("During");
  };

  const getAllReports = async () => {
    setLoading(true);
    try {
      let eTaskReportStatus = "";
      if (status === "All") {
        eTaskReportStatus = "";
      } else {
        eTaskReportStatus = status;
      }
      const response = await ReportServices.getReportsList(
        workforceId,
        eTaskReportStatus,
        priority,
        name,
        timeZone,
        pageNumber,
        pageSize,
        sortBy,
        sortDir
      );
      const { currentPage, totalPages, content, totalElements } =
        response.data.data;
      setTotalItems(totalElements);
      setData(content);
      console.log("general_report", content);
      setCount(totalPages);
      setOpen(true);
    } catch (error) {
      if (error.response) {
        let errorMessage = "";
        if (error.response.status === 401) {
          errorMessage = error.response.data.message;
        } else if (
          error.response.status === 400 ||
          error.response.status === 500
        ) {
          errorMessage = error.response.data.message;
        }
        setMessage(errorMessage);
        setSeverity("error");
        setOpen(true);
      }
      setCount(0);
    } finally {
      setLoading(false);
    }
  };

  const handleImageHover = (imageUrl) => {
    const popupImage = document.getElementById("popupImage");
    popupImage.src = imageUrl;
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "block";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      if (imageLinks[i].getAttribute("href") !== imageUrl) {
        imageLinks[i].style.opacity = "0.5";
      }
    }
  };

  const handleImageLeave = () => {
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "none";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      imageLinks[i].style.opacity = "1";
    }
  };

  useEffect(() => {
    if (page !== undefined && timeZone !== "") {
      getAllReports();
    }
  }, [page, pageSize, status, sortBy, sortDir]);

  function getCompanies(e) {
    setLoading(false);
    if (e !== undefined) {
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  const handleDownloadReport = async () => {
    try {
      const reportID = modalData?.reportId;

      // Constructing static URL
      const downloadUrl = `https://tappme-api.digitalnoticeboard.biz/tappme-api-staging/worker/report/download/pdf?reportId=${reportID}&timezoneId=${timeZone}`;

      // Create an anchor element
      const link = document.createElement("a");

      // Set the href attribute to the download URL
      link.href = downloadUrl;

      // Set the download attribute to specify the filename
      link.download = "case_report.pdf";

      // Simulate a click on the anchor element
      link.click();
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangePriority = (e) => {
    const priority = e.target.value;
    setPriority(priority);
  };

  const handleSearch = () => {
    getAllReports();
  };

  function formatDateNew(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;
    return strTime;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-3 col-sm-4 col-lg-3">
              <h5 className="mb-0">{t("Task Reports")}</h5>
            </div>
            <div className="col-12 col-md-9 col-sm-8 col-lg-9">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <span className="dropdown1">
                  <button
                    className="btn btn-outlineStyle-secondary dropdown-toggle btn-blue font-14 ml-1"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {status}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end border-b ">
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "All" && "active"
                        }`}
                        onClick={getAllAllocation}
                      >
                        {t("All")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "Before" && "active"
                        }`}
                        onClick={getBeforeAllocation}
                      >
                        {t("Before")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "During" && "active"
                        }`}
                        onClick={getDuringAllocation}
                      >
                        {t("During")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "After" && "active"
                        }`}
                        onClick={getAfterAllocation}
                      >
                        {t("After")}
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
          <div
            className="col-12 col-lg-4 col-xl-4"
            style={{ marginLeft: "18px" }}
          >
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              label={t("Search by Name")}
              variant="outlined"
              value={name}
              onChange={onChangeName}
            />
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <FormControl sx={{ m: 1, minWidth: 200 }} fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Priority")}
              </InputLabel>
              <Select
                labelId="priority-status"
                id="priority-status"
                label={t("Priority")}
                name="priority"
                value={priority}
                onChange={onChangePriority}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                <MenuItem value={`High`}>{t("High")}</MenuItem>
                <MenuItem value={`Medium`}>{t("Medium")}</MenuItem>
                <MenuItem value={`Low`}>{t("Low")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <Button variant="contained" color="primary" onClick={handleSearch}>
              {t("Search")}
            </Button>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <ServerSideTable
              pageSize={pageSize}
              page={page}
              sortBy={sortBy}
              sortDir={sortDir}
              count={count}
              functionName={getCompanies}
              data={[]}
              columns={columns}
              loading={true} // Set loading to true when data is loading
              idname="reportId"
            />
          ) : (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={count > 0 ? data : []}
              columns={columns}
              loading={false}
              idname="reportId"
            />
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header
          // closeButton
          className="border-0 mb-0 p-3 pb-0"
        ></Modal.Header>
        <Modal.Title className="font-20 text-dark text-center  mt-2">
          {t("Report Details")}
        </Modal.Title>

        <Modal.Body className="font-20 text-dark">
          <div className="px-5">
            <div className="border p-4 mb-4 border-20">
              <div className="d-flex mb-3">
                <label htmlFor="exampleDataList" className="form-label">
                  {t("Report ID")} :
                </label>
                {/* <Button variant="primary3" className="ms-4">
                  #{modalData.taskId} -
                  {modalData?.workerReportResponse?.reportId}
                </Button> */}
                <label
                  className="ms-4"
                  style={{
                    backgroundColor: "rgba(109, 164, 255, 1)",
                    color: "white",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  #{modalData.taskId} -{" "}
                  {modalData?.workerReportResponse?.reportId}
                </label>
              </div>
              <div className="d-flex mb-3">
                <label className="text-blue pe-3 mb-0">
                  <i className="fadeIn animated bx bx-time-five"></i>
                </label>
                <label className="form-label mb-0">
                  {/* setting date and time format */}
                  {t("Submitted on")}{" "}
                  {formatDateNew(modalData?.workerReportResponse?.createdAt)}{" "}
                  <br />
                  {t("at")}{" "}
                  {formatTime(modalData?.workerReportResponse?.createdAt)}
                </label>
              </div>
              <div className="d-flex mb-0">
                <label className="text-blue pe-3">
                  <i className="fadeIn animated bx bx-map"></i>
                </label>
                <label className="form-label">
                  {modalData?.workerReportResponse?.location}
                </label>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-5">
                <div className="d-flex">
                  <label className="form-label">{t("Task ID")} :</label>
                  <label className="form-label ms-4">{modalData.taskId}</label>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">{t("Employee_ID")} :</label>
                  <label className="form-label ms-4">
                    {modalData.employeeId}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">{t("Description")}:</label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.workerReportResponse?.description}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-8">
                <div className="d-flex">
                  <label className="form-label">
                    {t("Report submitted")} :
                  </label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.workerReportResponse?.etaskReportStatus} work
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-12">
                <label className="form-label mb-3">
                  {t("Images attached")}:
                </label>
                <section className="gallery_sec">
                  {modalData?.workerReportResponse?.workerReportDocResponses?.map(
                    (doc, index) => (
                      <a
                        data-fancybox="gallery"
                        href={doc?.documentUrl}
                        key={index}
                        className="image-link"
                        onMouseEnter={() => handleImageHover(doc.documentUrl)}
                        onMouseLeave={handleImageLeave}
                      >
                        <img
                          src={doc?.documentUrl}
                          alt={"file"}
                          className="zoom-img"
                        />
                      </a>
                    )
                  )}
                </section>
              </div>
            </div>
            <div id="imagePopup" style={{ display: "none" }}>
              <img id="popupImage" src="" alt="popup" />
            </div>

            <div className="border-0">
              <div className="col-12 mb-3">
                <a
                  class="btn btn-primary py-3 px-4"
                  onClick={handleDownloadReport}
                >
                  {t("Download Report")}
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="contained" onClick={handleClose}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskReport;
