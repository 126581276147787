import { loadNamespaces } from "i18next";
import http from "../../../../../baseURL/httpcommon";

//get all reports
const getReportsList = (
  workforceId,
  eTaskReportStatus,
  name,
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir
) => {
  debugger;
  return http.get(
    `general/report/supervisor?workforceId=${workforceId}&eTaskReportStatus=${eTaskReportStatus}&name=${name}&timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const ReportServices = {
  getReportsList,
};

export default ReportServices;
