import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const Failed = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          Payment Failed
        </Typography>
        {/*  <Typography sx={{ color: "text.secondary" }}>
          Your Payment Canceld, you will be notified on you registered mail id.
        </Typography> */}
        <Box
          component="img"
          src="https://1.bp.blogspot.com/-Lypzb5BW_2Q/XbVuZ51SYJI/AAAAAAAAAFo/VOMWTd7m-IE_5c2RqJC_MT_0MxOlqaeGQCLcBGAsYHQ/s1600/payment_fail01.jpg"
          sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
        />

        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </StyledContent>
    </Container>
  );
};

export default Failed;
