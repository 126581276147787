import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import StatusNavigator from "../../navigator";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ForgetApi from "./services";
import SupervisorServices from "./services";

import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { Password } from "@mui/icons-material";

const SetNewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [validated, setValidated] = useState(false);
  const [loading, setloading] = useState(false);
  const path = location.state.path;
  console.log(path);

  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  const username = jwt.username;
  console.log("userName", username);

  const dataJson = {
    newPassword: "",
    confirmPassword: "",
  };

  const [data, setData] = useState(dataJson);
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  console.log("error", errors);

  //setValues
  const setValue = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      handleSetNewPassword();
      setValidated(true);
    }
    setValidated(false);
  };

  const handleSetNewPassword = async () => {
    debugger;
    setloading(true);
    // Reset error state
    setErrors({
      newPassword: "",
      confirmPassword: "",
    });

    try {
      // Make POST request to set new password
      const response = await SupervisorServices.postSetNewPassword(
        data,
        username
      );

      // Handle successful response
      if (response.status === 200) {
        const responseData = response.data;
        console.log("responseData", responseData);
        setMessage(responseData.message);
        setSeverity("success");
        setOpen(true);
        setloading(false); // Stop loader
        navigate("/dashboard/supervisor/home");
      }
    } catch (errorState) {
      // Handle error response from the server
      if (errorState.response && errorState.response.status === 400) {
        const errorData = errorState.response.data;
        console.log("errorState", errorData);

        if (errorData.errorState) {
          const fieldErrors = errorData.errorState;

          // Check if setNewPasswordReq error exists and display it in the toast
          if (
            fieldErrors.setNewPasswordReq &&
            fieldErrors.setNewPasswordReq.length > 0
          ) {
            // Display the first error message from the setNewPasswordReq array in the toast
            setOpen(true);
            setMessage(fieldErrors.setNewPasswordReq[0]);
            setSeverity("error");
          } else {
            // Set error messages for individual fields (newPassword, confirmPassword)
            setErrors({
              newPassword: fieldErrors.newPassword
                ? fieldErrors.newPassword
                : "",
              confirmPassword: fieldErrors.confirmPassword
                ? fieldErrors.confirmPassword
                : "",
            });
          }
        } else if (errorData.message) {
          // Show the general error message in the toast if available
          setOpen(true);
          setMessage(errorData.message);
          setSeverity("error");
        }
      }
      setloading(false); // Stop loader for other error cases
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <StatusNavigator />
      <div className="wrapper">
        <main className="authentication-content">
          <div className="container">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden w-100 mt-3">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login align-items-center justify-content-center">
                    <div className="px-4 px-lg-5 text-center">
                      <img
                        className="me-2 mb-3 mt-lg-5 pt-lg-5"
                        src="/assets/images/login-img.png"
                        alt="login-img"
                      />
                      <p className="mt-lg-3 mb-4">
                        Your all-in-one solution designed to streamline
                        registration processes, enhance workflow visibility, and
                        drive productivity across a wide range of industries
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <div className="text-center">
                        <img
                          className="me-2 mb-3"
                          src="/assets/images/tapme-icon.svg"
                          width="66"
                          alt=""
                        />
                        <h3 className="card-title mb-3">Set New Password</h3>
                        <p style={{ color: "#1E73FF", fontSize: "12px" }}>
                          Please set new password to continue
                        </p>
                      </div>
                      <Form
                        className="form-body pt-2"
                        noValidate
                        validated={validated}
                      >
                        <div className="row">
                          <div className="col-12 mt-3">
                            <Form.Group
                              controlId="validationCustom03"
                              className="position-relative"
                            >
                              <div className="position-absolute translate-middle-y2 search-icon1 px-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                                    fill="#1E73FF"
                                  />
                                </svg>
                              </div>

                              <Form.Control
                                className="form-control radius-4 ps-5 height-50"
                                required
                                type="password"
                                placeholder="Enter New password"
                                name="newPassword"
                                maxLength={15}
                                minLength={8}
                                pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$"
                                onChange={setValue}
                                isInvalid={!!errors.newPassword}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.newPassword &&
                                  errors.newPassword.map(
                                    (errorMessage, index) => (
                                      <div key={index}>
                                        &#8226; {errorMessage}
                                      </div>
                                    )
                                  )}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-12 mt-3">
                            <Form.Group
                              controlId="validationCustom04"
                              className="position-relative"
                            >
                              <div className="position-absolute translate-middle-y2 search-icon1 px-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                                    fill="#1E73FF"
                                  />
                                </svg>
                              </div>

                              <Form.Control
                                className="form-control radius-4 ps-5 height-50"
                                required
                                type="password"
                                placeholder="Confirm password"
                                name="confirmPassword"
                                maxLength={15}
                                minLength={8}
                                pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$"
                                onChange={setValue}
                                isInvalid={!!errors.confirmPassword}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword &&
                                  errors.confirmPassword.map(
                                    (errorMessage, index) => (
                                      <div key={index}>
                                        &#8226; {errorMessage}
                                      </div>
                                    )
                                  )}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        {/* Submit Button */}

                        <div className="d-grid mt-4">
                          <button
                            type="button"
                            className="btn btn-primary login-btn height-50"
                            onClick={handleSubmit}
                            disabled={loading} // Disable button while loading
                          >
                            {loading ? (
                              <CircularProgress size={25} />
                            ) : (
                              "Update Password"
                            )}
                          </button>
                        </div>
                      </Form>
                      <div className="text-center mt-4">
                        <Link to="/" className="text-muted font-size-15">
                          Back to Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SetNewPassword;
