import http from "../baseURL/httpcommon";

const checkUserStatus = (userId) => {
  return http.get(`/user/get/${userId}`);
};

const HomeApi = {
  checkUserStatus,
};

export default HomeApi;
