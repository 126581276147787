import http from "../../../../baseURL/httpcommon";

//------------------ Chekin Approve/Reject-----------

const ApproveChekInRequest = (requestType, requestId, status) => {
  debugger;
  console.log("status", status);
  console.log("requestId", requestId);
  console.log("requestType", requestType);
  return http.put(
    `/api/checkinout/${requestType}/${requestId}/status?status=${status}`
  );
};

const RejectCheckInRequest = (requestType, requestId, status) => {
  debugger;
  const formData = new FormData();
  // formData.append("requestType", requestType);
  // formData.append("requestId", requestId);
  // formData.append("status", status);
  return http.put(
    `/api/checkinout/${requestType}/${requestId}/status?status=${status}`
  );
};
//------------------ Chekout Approve/Reject-----------

const ApproveChekOutRequest = () => {
  const data = "testdata";
  return http.post("API URL", data);
};

const RejectCheckOutRequest = () => {
  const data = "testdata";
  return http.post("API URL", data);
};

//------- fetch all checkin/Checkout requests ---------------

const AllCheckINOUTRequests = (supervisorId, timezoneId) => {
  debugger;
  return http.get(
    `api/checkinout/checkin/list?supervisorId=${supervisorId}&timezoneId=${timezoneId}`
  );
};
//------------overtime request-------------------
const ApproveOvertimeRequest = (requestType, requestId, status) => {
  debugger;
  console.log("status", status);
  console.log("requestId", requestId);
  console.log("requestType", requestType);
  return http.put(
    `/api/checkinout/overtime/${requestType}/${requestId}/status?status=${status}`
  );
};
const RequestServices = {
  ApproveChekInRequest,
  RejectCheckInRequest,
  ApproveChekOutRequest,
  RejectCheckOutRequest,
  AllCheckINOUTRequests,
  ApproveOvertimeRequest,
};

export default RequestServices;
