import React, { useEffect, useState, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { Link, useLocation } from "react-router-dom";
import ServerSideTable from "../../../common components/serverSideTable";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form, FormControl, InputGroup } from "react-bootstrap";
// import CircularProgress from "@material-ui/core/CircularProgress";

import TasksServices from "./services";
import { CircularProgress } from "@mui/material";

const WorkerTask = () => {
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const location = useLocation();

  console.log("location__records", location.state.rowData.name);
  console.log("location_row_data_12", location.state);
  const userId = location.state.rowData.id;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [priority, setPriority] = useState("Pending");
  const [totalItems, setTotalItems] = useState("");

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      field: "taskId",
      headerName: t("Task ID"),
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("Task Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "etaskStatus",
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor:
              params.value === "COMPLETED"
                ? "#008000"
                : params.value === "IN_PROGRESS" || params.value === "PENDING"
                ? "#AFAFAF"
                : "#E5B304",
            width: "100px",
            fontSize: "12px",
          }}
          size="medium"
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "employeeId",
      headerName: t("Worker ID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dueDate",
      headerName: t("Due Date"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "priority",
      headerName: t("Priority"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor:
              params.value === "High"
                ? "#FF0000"
                : params.value === "Low"
                ? "#0ACF83"
                : "#E5B304",
            width: "100px",
            fontSize: "12px",
          }}
          size="small"
        >
          {params.value}
        </Button>
      ),
    },
  ];

  //Get specific worker's tasks
  const getWorkerTasks = async () => {
    debugger;
    setLoading(true);
    try {
      const workforceId = decodetoken.workforceId;
      const response = await TasksServices?.getWorkerTasksList(
        workforceId,
        userId,
        priority,
        pageNumber,
        pageSize
      );
      const { totalPages, totalElements, content } = response.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      console.log("employeeName", content.employeeName);
      console.log("task_worker_Data", content);
      setCount(1);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 400) {
          setMessage(error.response.data.message);
        } else if (error.response.status === 404) {
          setMessage(error.response.data.message);
        }
        setSeverity("error");
        setOpen(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after fetching data (whether successful or not)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handlePriority = (status) => {
    debugger;
    setPriority(status);
  };

  useEffect(() => {
    setLoading(false);
    getCompanies();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    debugger;
    //setting page Number and PageSize
    if (pageNumber !== undefined) {
      getWorkerTasks();
      setLoading(false);
    }
  }, [pageNumber, pageSize, priority]);

  useEffect(() => {
    getCompanies();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);

      setPage(e.page == undefined ? 0 : e.page);

      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="mt-4">
              {" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "2rem",
                    fontWeight: 400,
                    marginLeft: "1rem",
                  }}
                >
                  {location.state.rowData.name}
                </span>
                <label
                  style={{
                    backgroundColor: "#1E73FF",
                    color: "white",
                    fontSize: "13px",
                    padding: "5px",
                    marginLeft: "2rem",
                  }}
                >
                  #{location.state.rowData.employeeId}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-2 col-lg-2 mt-5">
              <h5 className="mb-0"> {t("Tasks")}</h5>
            </div>

            <div className="col-12 col-md-7 col-lg-7"></div>

            <div className="col-12 col-md-3 col-lg-3 ">
              <div className="text-end">
                <button
                  className="btn btn-outline-secondary dropdown-toggle btn-blue font-14 ml-2"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {priority}
                </button>
                <ul className="dropdown-menu dropdown-menu-end border-b ">
                  <li>
                    <a
                      className={`dropdown-item ${
                        priority === "Pending" && "active"
                      }`}
                      onClick={(e) => handlePriority("Pending")}
                    >
                      {t("Pending")}
                    </a>
                  </li>
                  <li>
                    <a
                      className={`dropdown-item ${
                        priority === "Completed" && "active"
                      }`}
                      onClick={(e) => handlePriority("Completed")}
                    >
                      {t("Completed")}
                    </a>
                  </li>
                  <li>
                    <a
                      className={`dropdown-item ${
                        priority === "Working" && "active"
                      }`}
                      onClick={(e) => handlePriority("Working")}
                    >
                      {t("Working")}
                    </a>
                  </li>
                </ul>
                <Link
                  to="/dashboard/supervisor/workers"
                  className="btn btn-outline-secondary btn-blue font-14 ms-2"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          {loading > 0 ? (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={data}
              columns={columns}
              loading={true}
              idname="taskId"
            />
          ) : (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={count > 0 ? data : []}
              columns={columns}
              loading={false}
              idname="taskId"
            />
          )}
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default WorkerTask;
