import React, { useEffect, useState } from "react";
import RequestServices from "./services";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { formatDateTime } from "../../../../helper/DateFormater";

const Request = () => {
  const [showModal, setShowModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState();
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [flag, setFlag] = useState();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState({
    employeeId: "",
    name: "",
    time: "",
    reason: "",
  });

  const [approve, setApprove] = useState(); //to hold the request data

  console.log("data_state", data);
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const supervisorId = decodedToken.id;

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getAllRequests();
    }
  }, [timeZone]); // This effect runs when timeZone changes

  useEffect(() => {
    if (showModal) {
      // Update 'data' state when the modal is opened
      setData({
        ...data,
        employeeId: data.name,
        name: data.name,
        reason: data.reason,
        time: data.time,
      });
    }
  }, []);

  //request reject
  const handleRejectRequest = (requestFlag) => {
    setDisabledBtn(true);
    debugger;
    const requestId = approve.requestId;
    let requestType = approve.flag;

    // Declare status outside the if-else blocks
    let status = null;
    //condition for overtime request
    if (
      requestType === "CHECK-IN-OVER-TIME" ||
      requestType === "CHECK-OUT-OVER-TIME"
    ) {
      if (requestType === "CHECK-IN-OVER-TIME") {
        requestType = "CHECK-IN";
      } else {
        requestType = "CHECK-OUT";
      }
      if (requestFlag === "APPROVE") {
        status = "APPROVED";
      } else {
        status = "DENIED";
      }
      if (requestId && requestType) {
        debugger;
        RequestServices.ApproveOvertimeRequest(requestType, requestId, status)
          .then((response) => {
            debugger;
            setDisabledBtn(false);
            if (response.status === 200) {
              debugger;
              setShowModal(false);
              setShowRequestModal(false);
              setMessage(response.data.message);
              setRequests((prevData) => {
                console.log("Previous check-in requests:", prevData);

                // Filter out the approved request and log each requestId
                const updatedData = prevData.filter((req) => {
                  console.log("Checking requestId:", req.requestId); // Log each requestId
                  return req.requestId !== requestId;
                });

                console.log("Updated check-in requests:", updatedData);
                return updatedData;
              });
              console.log("response_data_message", response.data.message);
              setSeverity("success");
              setOpen(true);

              // getAllRequests();
            } else {
              console.error("Error in status update");
              setMessage(response.data.message);
              setSeverity("error");
              setOpen(true);
              setDisabledBtn(false);
            }
          })
          .catch((error) => {
            console.error("Error while processing request:", error);
            setMessage(error.response.data.message);
            setSeverity("error");
            setOpen(true);
            setDisabledBtn(false);
          });
      }
    } else {
      debugger;
      if (requestType === "CHECK-IN" || requestType === "CHECK-OUT") {
        if (requestFlag === "REJECT") {
          status = "DENIED";
        } else {
          status = "APPROVED";
        }
      } else if (requestType === "OUTSIDE_REQUEST") {
        if (requestFlag === "REJECT") {
          status = "DENIED";
        } else {
          status = "APPROVED";
        }
      } else if (requestType === "INSIDE-REQUEST") {
        if (requestFlag === "APPROVE") {
          status = "APPROVED";
        } else {
          status = "DENIED";
        }
      }
      if (requestId && requestType) {
        debugger;
        RequestServices.RejectCheckInRequest(requestType, requestId, status)
          .then((response) => {
            debugger;
            setDisabledBtn(false);
            if (response.status === 200) {
              debugger;
              setShowModal(false);
              setShowRequestModal(false);
              setMessage(response.data.message);
              setRequests((prevData) => {
                console.log("Previous check-in requests:", prevData);

                // Filter out the approved request and log each requestId
                const updatedData = prevData.filter((req) => {
                  console.log("Checking requestId:", req.requestId); // Log each requestId
                  return req.requestId !== requestId;
                });

                console.log("Updated check-in requests:", updatedData);
                return updatedData;
              });
              console.log("response_data_message", response.data.message);
              setSeverity("success");
              // setOpen(true);

              // getAllRequests();
            } else {
              console.error("Error in status update");
              setMessage(response.data.message);
              setSeverity("error");
              setOpen(true);
              setDisabledBtn(false);
            }
          })
          .catch((error) => {
            console.error("Error while processing request:", error);
            setMessage(error);
            setSeverity("error");
            setOpen(true);
            setDisabledBtn(false);
          });
      }
    }
  };

  //request Approve
  const handleApproveRequest = (requestFlag, data) => {
    debugger;
    setDisabledBtn(true);
    console.log(approve);

    let status = null;
    let requestType = null;
    let requestId = null;

    if (
      data &&
      (data.flag === "OUTSIDE-REQUEST" || data.flag === "INSIDE-REQUEST")
    ) {
      requestId = data.requestId;
      requestType = data.flag;
    } else {
      requestId = approve.requestId;
      requestType = approve.flag;
    }

    //condition for overtime request
    if (
      requestType === "CHECK-IN-OVER-TIME" ||
      requestType === "CHECK-OUT-OVER-TIME"
    ) {
      if (requestType === "CHECK-IN-OVER-TIME") {
        requestType = "CHECK-IN";
      } else {
        requestType = "CHECK-OUT";
      }
      if (requestFlag === "APPROVE") {
        status = "APPROVED";
      } else {
        status = "DENIED";
      }
      if (requestId && requestType) {
        debugger;
        RequestServices.ApproveOvertimeRequest(requestType, requestId, status)
          .then((response) => {
            debugger;

            setDisabledBtn(false);

            if (response.status === 200) {
              debugger;
              setShowModal(false);
              setShowRequestModal(false);
              setMessage(response.data.message);
              console.log("response_data_message", response.data.message);
              setSeverity("success");
              setRequests((prevData) => {
                console.log("Previous check-in requests:", prevData);

                // Filter out the approved request and log each requestId
                const updatedData = prevData.filter((req) => {
                  console.log("Checking requestId:", req.requestId); // Log each requestId
                  return req.requestId !== requestId;
                });

                console.log("Updated check-in requests:", updatedData);
                return updatedData;
              });
              setOpen(true);
              handleCloseGoOutSide();

              //  getAllRequests();
            } else {
              console.error("Error in status update");
              setMessage(response.data.message);
              setSeverity("error");
              setOpen(true);
              setDisabledBtn(false);
            }
          })
          .catch((error) => {
            console.error("Error while processing request:", error);
            setMessage(error.response.data.message);
            setSeverity("error");
            setOpen(true);
            setDisabledBtn(false);
          });
      }
    } else {
      if (requestType === "CHECK-IN" || requestType === "CHECK-OUT") {
        if (requestFlag === "REJECT") {
          status = "DENIED";
        } else {
          status = "APPROVED";
        }
      } else if (requestType === "OUTSIDE-REQUEST") {
        if (requestFlag === "APPROVED") {
          status = "APPROVED";
        } else {
          status = "DENIED";
        }
      } else if (requestType === "INSIDE-REQUEST") {
        if (requestFlag === "APPROVE") {
          status = "APPROVED";
        } else {
          status = "DENIED";
        }
      }

      if (requestId && requestType) {
        debugger;
        RequestServices.ApproveChekInRequest(requestType, requestId, status)
          .then((response) => {
            debugger;
            setDisabledBtn(false);
            if (response.status === 200) {
              debugger;
              setMessage(response.data.message);
              setRequests((prevData) => {
                console.log("Previous check-in requests:", prevData);

                // Filter out the approved request and log each requestId
                const updatedData = prevData.filter((req) => {
                  console.log("Checking requestId:", req.requestId); // Log each requestId
                  return req.requestId !== requestId;
                });

                console.log("Updated check-in requests:", updatedData);
                return updatedData;
              });
              console.log("response_data_message", response.data.message);
              setSeverity("success");
              setOpen(true);
              setShowModal(false);
              setShowRequestModal(false);
              handleCloseGoOutSide();
              // getAllRequests();
            } else {
              console.error("Error in status update");
              setMessage(response.data.message);
              setSeverity("error");
              setOpen(true);
              setDisabledBtn(false);
            }
          })
          .catch((error) => {
            console.error("Error while processing request:", error);
            setMessage(error.response.data.message);
            setSeverity("error");
            setOpen(true);
            setDisabledBtn(false);
          });
      }
    }
  };

  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleApproveMessageModal = (request) => {
    debugger;
    console.log("request_data", request);
    setApprove(request);
    setFlag("APPROVE");
    setShowModal(true);
  };

  const handleRejectMessageModal = (request) => {
    debugger;
    setApprove(request);
    setFlag("REJECT");
    console.log("request Reject", request);
    setShowModal(true);
  };

  const getAllRequests = async () => {
    try {
      debugger;
      const response = await RequestServices.AllCheckINOUTRequests(
        supervisorId,
        timeZone
      );
      if (response.status === 200) {
        const responseData = response.data;
        console.log("respData_request", responseData);
        console.log("flag", responseData.flag);
        if (
          responseData.status &&
          responseData.data &&
          responseData.data.length > 0
        ) {
          const filteredRequests = responseData.data;
          // Update state outside the map function
          setRequests(filteredRequests);
          setData(filteredRequests);
          console.log("filteredRequests", filteredRequests);
        } else {
          console.log("No data received or invalid response");
        }
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      if (error.response && error.response.status === 404) {
        setMessage(error.response.data.message);
        setSeverity("error");
        // setOpen(true);
      }
    }
  };

  // useEffect(() => {
  //   getAllRequests();
  // }, []);

  // function formatDateTime(dateTimeString) {
  //   const dateTime = new Date(dateTimeString);

  //   // Check if the date is valid
  //   if (isNaN(dateTime.getTime())) {
  //     return "Invalid Date";
  //   }

  //   const options = {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //     hourCycle: "h23",
  //   };
  //   let formattedDateTime = dateTime.toLocaleString("en-US", options);
  //   formattedDateTime = formattedDateTime.replace(/,\s*/, " - ");
  //   // Convert AM/PM to uppercase
  //   formattedDateTime = formattedDateTime.replace(/\b(?:am|pm)\b/gi, (match) =>
  //     match.toUpperCase()
  //   );

  //   return formattedDateTime;
  //   // return dateTime.toLocaleString("en-GB", options);
  // }

  //-------------View  Details modal Accept and Reject functions -------------------
  const handleModalAccept = (request) => {
    debugger;
    setApprove(request);
    handleApproveRequest("APPROVED", request);
  };

  const handleModalReject = (request) => {
    debugger;
    setApprove(request);
    handleApproveRequest("REJECT", request);
  };

  const handleClose = () => {
    debugger;
    setShowModal(false);
    setShowRequestModal(false);
    setOpen(false);
  };

  console.log("modelsatus1", showRequestModal);
  console.log("modelsatus2", showRequestModal);

  const [showGoOutSide, setShowGoOutSide] = useState(false);
  const [goOutSideData, setGoOutSideData] = useState({});
  const handleShowGoOutSide = (requestData) => {
    setShowGoOutSide(true);
    setGoOutSideData(requestData);
  };

  const handleCloseGoOutSide = () => setShowGoOutSide(false);

  const handleCloseCheckInOut = () => {
    setShowModal(false);
    setDisabledBtn(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="row g-3 align-items-center">
          <div className="col-4 col-md-2 col-lg-2">
            <h5 className="mb-5 font-18">{t("Requests")}</h5>
          </div>
          <div className="col-8 col-md-10 col-lg-10">
            <div className="text-right"></div>
          </div>
        </div>
        {requests && requests.length > 0 ? (
          requests.map((request) => (
            <div key={request.id}>
              {!(request.flag === "OUTSIDE-REQUEST") ? (
                <div className="card radius-15">
                  <div className="card-body card-border align-items-center">
                    <div className="float-end text-dark">
                      <button
                        type="button"
                        className="btn btn-success2 px-3 px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmationModal"
                        style={{ marginRight: "5px" }}
                        onClick={() => handleApproveMessageModal(request)}
                      >
                        {t("Approve")}
                      </button>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmationModal"
                        className="btn btn-danger2 px-3"
                        onClick={() => handleRejectMessageModal(request)}
                      >
                        {t("Reject")}
                      </button>
                    </div>

                    <p className="card-text pt-2">
                      <b>
                        {request.name} ({request.employeeId})
                      </b>{" "}
                      {t("has requests to")}{" "}
                      <span
                        style={{
                          color:
                            request.flag === "CHECK-IN" ? "#008000" : "#FF1C1C",
                        }}
                      >
                        {request.flag}
                      </span>{" "}
                      <span className="text-primary">
                        {t("on")} {formatDateTime(request.requestTime)}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              {request.flag === "OUTSIDE-REQUEST" && (
                <div className="card radius-15">
                  <div className="card-body card-border align-items-center">
                    <div className="float-end text-dark">
                      <button
                        type="button"
                        className="btn btn-primary px-3 font-14 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        onClick={() => handleShowGoOutSide(request)}
                      >
                        {t("View Details")}
                      </button>
                    </div>
                    <p className="card-text pt-2">
                      <b>
                        {request.name} ({request.employeeId})
                      </b>{" "}
                      {t("has requesting to go outside the Geofence boundary")}{" "}
                      <span className="text-primary">
                        {t("on")} {formatDateTime(request.requestTime)}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="ms-3">{t("No_records_found")}</p>
        )}
      </div>
      {/* request modal for outside request */}

      <Modal show={showGoOutSide} onHide={handleCloseGoOutSide}>
        <Modal.Body>
          <p className="font-18 text-dark text-center mb-5">
            {t("Request for going outside the geofence from")}{" "}
            <span className="text-blue">
              {goOutSideData.name}(#{goOutSideData.employeeId})
            </span>
          </p>

          <div className="row">
            <div className="col-12 mb-3">
              <label className="form-label" style={{ marginRight: "23rem" }}>
                {t("Reason")}
              </label>
              <p>{goOutSideData.reason}</p>

              <div className="mt-4">
                <button
                  disabled={disabledBtn}
                  type="button"
                  className="btn btn-success2 px-3 py-3 w-100 mb-3 font-15"
                  onClick={() => handleModalAccept(goOutSideData)}
                >
                  {t("Accept")}
                </button>
                <button
                  disabled={disabledBtn}
                  type="button"
                  className="btn btn-danger2 px-3 py-3 w-100 mb-3 font-15"
                  onClick={() => handleModalReject(goOutSideData)}
                >
                  {t("Reject")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for check-IN , CHECK-OUT  Request */}

      <Modal show={showModal} onHide={handleCloseCheckInOut}>
        <Modal.Body className="modal-content text-center">
          {flag === "APPROVE" ? (
            <div className="modal-body font-18 p-5 text-dark">
              {t("Are you sure you want to approve the request?")}
            </div>
          ) : (
            <div className="modal-body font-18 p-5 text-dark">
              {t("Are you sure you want to reject the request?")}
            </div>
          )}

          <div className="pb-5 border-0">
            {flag === "APPROVE" ? (
              <button
                disabled={disabledBtn}
                type="button"
                className="btn btn-primary px-4 py-3 w-25"
                style={{ marginRight: "1.5rem" }}
                onClick={() => handleApproveRequest("APPROVE")}
              >
                {t("Yes")}
              </button>
            ) : (
              <button
                disabled={disabledBtn}
                type="button"
                className="btn btn-primary px-4 py-3 w-25"
                style={{ marginRight: "1.5rem" }}
                onClick={() => handleRejectRequest("REJECT")}
              >
                {t("Yes")}
              </button>
            )}

            <button
              type="button"
              className="btn btn-danger px-4 py-3 w-25 "
              data-bs-dismiss="modal"
              onClick={handleCloseCheckInOut}
            >
              {t("No")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Request;
