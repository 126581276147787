import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getNotification = (workforceId, timezoneId) => {
  debugger;
  return http.get(
    `/notification/worker?workforceId=${workforceId}&timezoneId=${timezoneId}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const findNotificationById = (id, timezoneId) => {
  return http.get(
    `/notification/get?notificationId=${id}&timezoneId=${timezoneId}`
  );
};

const NotificaionServices = {
  getNotification,
  findNotificationById,
};

export default NotificaionServices;
