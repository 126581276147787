import http from "../baseURL/httpcommon";
import authHeader from "../baseURL/authHeader";

const getNotification = (workforceId, timezoneId) => {
  return http.get(
    `/notification/worker?workforceId=${workforceId}&timezoneId=${timezoneId}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const findNotificationById = (id, timezoneId) => {
  return http.get(
    `/notification/get?notificationId=${id}&timezoneId=${timezoneId}`
  );
};

const NotificationServices = {
  getNotification,
  findNotificationById,
};

export default NotificationServices;
