import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getnotify = (email) => {
  debugger;
  console.log("email: " + email);
  return http.post(`notification/testnotification/${email}`);
};
const getSettingData = (supervisorId) => {
  debugger;
  return http.get(`/home/status/${supervisorId}`);
};

// -----link social account
const linkSocialAccount = (data) => {
  debugger;
  return http.post(`/auth/link/account`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const changePassword = (data) => {
  return http.post(`/user/changepassword`, data, {
    headers: {
      ...authHeader(),
    },
  });
};
//disconnect account
const postDisconnectGoogleAccount = (userId, providerType) => {
  debugger;
  return http.post(
    `auth/disconnect/account?userId=${userId}&providerType=${providerType}`,
    null,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const postDisconnectFBAccount = (userId, providerType) => {
  debugger;
  return http.post(
    `auth/disconnect/account?userId=${userId}&providerType=${providerType}`,
    null,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const SettingServices = {
  getnotify,
  getSettingData,
  linkSocialAccount,
  changePassword,
  postDisconnectGoogleAccount,
  postDisconnectFBAccount,
};

export default SettingServices;
