import http from "../../baseURL/httpcommon";
import authHeader from "../../baseURL/authHeader";

const getCompanyList = (email) => {
  debugger;
  return http.get(`company/byEmail/${email}`);
};
const supervisorSignIn = (data) => {
  debugger;

  return http.post(`auth/login`, data);
};

//social signin
const supervisorSocialSignIn = (data) => {
  return http.post(`auth/social/login`, data);
};
//getting social company list
const getSocialCompanyList = (email) => {
  debugger;
  console.log(email);
  return http.get(`company/social/byEmail/${email}`);
};
const getTop = (data) => {
  debugger;
  return http.post("user/forgotpassword", data);
};
const resetPassword = (data) => {
  return http.post("user/resetpassword", data);
};
//setNewPassword
const postSetNewPassword = (data, username) => {
  debugger;
  data = { ...data, username };
  return http.post(`user/setnewpassword`, data);
};

const SupervisorServices = {
  getCompanyList,
  supervisorSignIn,
  getSocialCompanyList,
  supervisorSocialSignIn,
  getTop,
  resetPassword,
  postSetNewPassword,
};

export default SupervisorServices;
