import http from "../baseURL/httpcommon";

//------------------ Chekin Approve/Reject-----------

const getUser = (userId) => {
  return http.get(`/user/get/${userId}`);
};

const UserService = {
  getUser,
};

export default UserService;
