import http from "../../../../../baseURL/httpcommon";

//get all reports
const getReportsList = (
  workforceId,
  eTaskReportStatus,
  priority,
  name,
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir
) => {
  return http.get(
    `worker/report/supervisor/list?workforceId=${workforceId}&eTaskReportStatus=${eTaskReportStatus}&eTaskPriority=${priority}&taskName=${name}&timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const ReportServices = {
  getReportsList,
};

export default ReportServices;
