import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Avatar, Box, Chip, CircularProgress, Typography } from "@mui/material";
import NotificaionServices from "./services";
import { grey } from "@mui/material/colors";
import { truncateName40 } from "../../../../helper/StringSlicer";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t } = useTranslation();
  const loading = false;
  const [notification, setNotification] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  const workforceId = jwt?.workforceId;

  const [timeZone, setTimeZone] = useState("");
  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getNotification();
    }
  }, [timeZone]); //

  const getNotification = () => {
    debugger;
    NotificaionServices.getNotification(workforceId, timeZone)
      .then((response) => {
        const data = response.data.data.notificationList;
        setNotification(data);
        console.log("data", data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("Notification not found:", error.data);
            // setMessage(error.response.message);
          } else if (
            error.response.status === 500 ||
            error.response.status === 400 ||
            error.reponse.status === 504
          ) {
            // setMessage(error.message);
          } else {
            console.log("Other error:", error.response.data);
            // setMessage(error.response.message);
          }
        } else {
          console.log("Error setting up request:", error.message);
          // setMessage(error.response.message);
        }
      });
  };

  const handleNotification = (id) => {
    debugger;
    NotificaionServices.findNotificationById(id, timeZone)
      .then((response) => {
        // setNotificationData(response.data.data);
        getNotification();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  function formatDateWithDay(inputDate) {
    const currentDate = new Date();
    const messageDate = new Date(inputDate);
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const messageDay = messageDate.getDate();
    const messageMonth = messageDate.getMonth();
    const messageYear = messageDate.getFullYear();

    if (
      currentDay === messageDay &&
      currentMonth === messageMonth &&
      currentYear === messageYear
    ) {
      let hours = messageDate.getHours();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutes = messageDate.getMinutes();
      return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    } else if (
      currentDay - messageDay === 1 &&
      currentMonth === messageMonth &&
      currentYear === messageYear
    ) {
      return "Yesterday";
    } else {
      const formattedDate = `${messageDay}/${(messageMonth + 1)
        .toString()
        .padStart(2, "0")}/${messageYear.toString().slice(2)}`;
      return formattedDate;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      display="flex"
      p={2}
      borderRadius={2}
      boxShadow={1}
      maxWidth={900}
      bgcolor="white"
    >
      <Box flex={1} p={2} bgcolor="white">
        <Typography variant="h6" gutterBottom>
          {t("Notifications")}
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Box maxHeight={550} overflow="auto">
            {notification.map((notification) => (
              <Box
                key={notification.id}
                display="flex"
                alignItems="flex-start"
                p={2}
                borderBottom={`1px solid ${grey[300]}`}
                onClick={() => {
                  handleNotification(notification.id);
                  setSelectedNotification(notification);
                }}
                sx={{ cursor: "pointer" }}
              >
                {/* <Avatar
                  src={`https://picsum.photos/50/50?random=${notification.id}`}
                  sx={{ bgcolor: grey[400], mr: 2 }}
                /> */}
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    {notification.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {truncateName40(notification.description)}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatDateWithDay(notification.timestamp)}
                  </Typography>
                  {notification.status === "RECEIVED" && (
                    <Chip
                      label="Unread"
                      color="primary"
                      size="small"
                      style={{ float: "right" }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {selectedNotification && (
        <Box flex={1} p={2} borderLeft={`1px solid ${grey[300]}`}>
          <Typography variant="h6" gutterBottom>
            {t("Notification Details")}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {selectedNotification.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {selectedNotification.description}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDateWithDay(selectedNotification.timestamp)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Notification;
