export const sliceAndEllipsis = (inputString) => {
  let isSpace = hasNoSpaces(inputString);
  if (inputString.length > 20 && isSpace) {
    return inputString.slice(0, 20) + "...";
  }
  return inputString;
};

export const sliceStringHandler = (inputString) => {
  if (inputString.length > 25) {
    return inputString.slice(0, 25) + "...";
  }
  return inputString;
};

function hasNoSpaces(inputString) {
  const spacePattern = /\s/;
  return !spacePattern.test(inputString);
}

export const truncateDescription = (description) => {
  if (!description || description.length <= 25) {
    return description;
  }
  const lastSpaceIndex = description.lastIndexOf(" ", 25);

  if (lastSpaceIndex !== -1) {
    return description.slice(0, lastSpaceIndex) + "...";
  } else {
    return description.slice(0, 25) + "...";
  }
};

export const truncateName20 = (description) => {
  if (!description || description.length <= 20) {
    return description;
  }
  const lastSpaceIndex = description.lastIndexOf(" ", 20);

  if (lastSpaceIndex !== -1) {
    return description.slice(0, lastSpaceIndex) + "...";
  } else {
    return description.slice(0, 20) + "...";
  }
};

export const truncateName25 = (description) => {
  if (!description || description.length <= 30) {
    return description;
  }
  const lastSpaceIndex = description.lastIndexOf(" ", 30);

  if (lastSpaceIndex !== -1) {
    return description.slice(0, lastSpaceIndex) + "...";
  } else {
    return description.slice(0, 30) + "...";
  }
};

export const truncateName40 = (description) => {
  if (!description || description.length <= 55) {
    return description;
  }
  const lastSpaceIndex = description.lastIndexOf(" ", 55);

  if (lastSpaceIndex !== -1) {
    return description.slice(0, lastSpaceIndex) + "...";
  } else {
    return description.slice(0, 55) + "...";
  }
};
